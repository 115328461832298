// src/hooks/useProduct.ts
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Product {
  id: string;
  name: string;
  image_url1?: string;
  image_url2?: string;
  image_url3?: string;
}

export function useProduct(brandId: string | undefined) {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [editedProduct, setEditedProduct] = useState<Product | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProducts = useCallback(async () => {
    if (!brandId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/brands/${brandId}/products`);
      setProducts(response.data);
      if (response.data.length > 0) {
        const latestProduct = response.data[response.data.length - 1];
        setSelectedProduct(latestProduct);
        setEditedProduct(latestProduct);
      }
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  const createProduct = useCallback(async (newProduct: Omit<Product, 'id'>): Promise<Product> => {
    if (!brandId) throw new Error('No brand selected');
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`/api/brands/${brandId}/products`, newProduct);
      setProducts(prevProducts => [...prevProducts, response.data]);
      return response.data;
    } catch (err) {
      console.error('Error creating product:', err);
      setError('Failed to create product');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  const updateProduct = useCallback(async (productId: string, updatedFields: Partial<Product>): Promise<Product> => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`/api/products/${productId}`, updatedFields);
      const updatedProduct = response.data;
      setProducts(prevProducts => 
        prevProducts.map(p => p.id === updatedProduct.id ? updatedProduct : p)
      );
      if (selectedProduct?.id === updatedProduct.id) {
        setSelectedProduct(updatedProduct);
        setEditedProduct(updatedProduct);
      }
      setIsDirty(false);
      return updatedProduct;
    } catch (error) {
      console.error('Error updating product:', error);
      setError('Failed to update product');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [selectedProduct]);

  const saveProductChanges = async () => {
    if (!isDirty || !editedProduct || !selectedProduct) return null;
    try {
      const response = await axios.put(`/api/products/${selectedProduct.id}`, editedProduct);
      setSelectedProduct(response.data);
      setProducts(prevProducts => 
        prevProducts.map(p => p.id === response.data.id ? response.data : p)
      );
      setEditedProduct(response.data);
      setIsDirty(false);
      return response.data;
    } catch (error) {
      console.error('Error saving product:', error);
      throw error;
    }
  };

  const deleteProduct = useCallback(async (productId: string): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      await axios.delete(`/api/products/${productId}`);
      setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
      if (selectedProduct?.id === productId) {
        setSelectedProduct(null);
        setEditedProduct(null);
      }
    } catch (err) {
      console.error('Error deleting product:', err);
      setError('Failed to delete product');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [selectedProduct]);

  const selectProduct = useCallback((productId: string | null) => {
    const product = productId ? products.find(p => p.id === productId) || null : null;
    setSelectedProduct(product);
    setEditedProduct(product);
    setIsDirty(false);
  }, [products]);

  const setEditedProductState = (updatedProduct: Product | ((prev: Product | null) => Product | null)) => {
    setEditedProduct(updatedProduct);
    setIsDirty(true);
  };

  const setIsDirtyState = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  useEffect(() => {
    if (brandId) {
      fetchProducts();
    } else {
      setProducts([]);
      setSelectedProduct(null);
      setEditedProduct(null);
    }
  }, [brandId, fetchProducts]);

  return { 
    products, 
    selectedProduct,
    editedProduct,
    setEditedProduct: setEditedProductState,
    loading, 
    error, 
    fetchProducts, 
    createProduct, 
    updateProduct, 
    deleteProduct, 
    saveProductChanges,
    selectProduct,
    isDirty,
    setIsDirty: setIsDirtyState
  };
}