import React, { useState, useEffect } from 'react';
import { X as CloseIcon, Check } from 'lucide-react';
import { Button } from "./ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { createCheckout, cancelSubscription } from '../services/lemonSqueezyService';
import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';

interface PricingPageProps {
  onClose: () => void;
}

const VARIANT_IDS = {
  BASIC: '586439',
  PRO: '586481'
};

interface UserData {
  id: string;
  email: string;
  plan_type: string;
  subscription_status: string;
  subscription_id: string;
}

const PricingPage: React.FC<PricingPageProps> = ({ onClose }) => {
  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [userId, setUserId] = useState<string | null>(null);
  const [userPlan, setUserPlan] = useState<string | null>(null);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [planToCancel, setPlanToCancel] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated && user) {
        try {
          setIsLoading(true);
          const token = await getAccessTokenSilently();
          
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/users/me`;
          const response = await axios.get<UserData>(apiUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });

          console.log('User data received:', response.data);

          if (response.data) {
            setUserId(response.data.id);
            setUserPlan(response.data.plan_type);
            setSubscriptionId(response.data.subscription_id);
            setSubscriptionStatus(response.data.subscription_status);
            console.log('Set subscription data:', {
              id: response.data.subscription_id,
              status: response.data.subscription_status
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to fetch user data');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  const handleCancelConfirmed = async () => {
    if (!subscriptionId) {
      console.error('No subscription ID found for cancellation');
      setError('No active subscription found');
      return;
    }

    try {
      setIsCheckoutLoading(true);
      console.log('Executing cancellation for:', subscriptionId);
      
      const response = await cancelSubscription(subscriptionId);
      console.log('Cancellation response:', response);
      
      setShowCancelDialog(false);
      
      alert('Your cancellation request has been submitted. Your plan will be updated once processed.');
      
      setTimeout(() => {
        window.location.reload();
      }, 3000);

    } catch (error) {
      console.error('Cancellation error:', error);
      setError('Failed to cancel subscription. Please try again or contact support.');
    } finally {
      setIsCheckoutLoading(false);
    }
  };

  const handlePlanAction = async (variantId: string, planType: string) => {
    if (!isAuthenticated || !user) {
      loginWithRedirect({
        appState: { returnTo: '/pricing' }
      });
      return;
    }

    if (isLoading || !userId) {
      return;
    }

    try {
      setIsCheckoutLoading(true);
      setError(null);

      if (userPlan === planType && subscriptionId && subscriptionStatus === 'active') {
        setPlanToCancel(planType);
        setShowCancelDialog(true);
      } else {
        const checkout = await createCheckout(
          variantId, 
          user.email || '', 
          userId
        );
        
        if (checkout.data?.attributes?.url) {
          window.location.href = checkout.data.attributes.url;
        } else {
          setError('Failed to create checkout');
        }
      }
    } catch (error) {
      console.error('Plan action error:', error);
      setError('Operation failed. Please try again later.');
    } finally {
      setIsCheckoutLoading(false);
    }
  };

  const getButtonText = (planType: string) => {
    if (isCheckoutLoading) return 'Processing...';
    
    // Make it match exactly how planType is being passed
    const isActive = userPlan === planType.toLowerCase() && subscriptionStatus === 'active';
    if (isActive) {
      return 'Cancel Plan';
    }
    
    return `Select ${planType} Plan`;
  };

  const getButtonClass = (planType: string) => {
    const isActive = userPlan === planType && subscriptionStatus === 'active';
    if (isActive) {
      return 'w-full bg-red-500 hover:bg-red-600';
    }
    return planType === 'basic' 
      ? 'w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white'
      : 'w-full bg-white text-black hover:bg-gray-100';
  };

  return (
    <div className="min-h-screen bg-black text-white p-8 max-w-screen-2xl mx-auto">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-12">
          <h1 className="text-2xl font-bold">Upgrade your plan</h1>
          <button onClick={onClose} className="p-2 hover:bg-gray-800 rounded-full transition-colors">
            <CloseIcon size={24} />
          </button>
        </div>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* Basic Plan */}
          <div className="relative bg-black p-8 rounded-xl border border-gray-700 max-w-md">
            <div className="absolute -top-4 left-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-1 rounded-full text-sm">
              Recommended for starters
            </div>
            <h2 className="text-2xl font-bold mb-4">Basic</h2>
            <div className="text-3xl font-bold mb-6">
              €9<span className="text-xl text-gray-400">/month</span>
            </div>
            <ul className="space-y-4 mb-8">
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>30 AI generations per day</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>Your brand style</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>Add logo to images</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>Email support</span>
              </li>
            </ul>
            <Button 
              className={getButtonClass('basic')}
              onClick={() => handlePlanAction(VARIANT_IDS.BASIC, 'basic')}
              disabled={isCheckoutLoading}
            >
              {isCheckoutLoading ? (
                <div className="flex items-center justify-center">
                  <span className="mr-2">Processing...</span>
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                </div>
              ) : (
                getButtonText('Basic')
              )}
            </Button>
          </div>

          {/* Pro Plan */}
          <div className="relative bg-black p-8 rounded-xl border border-gray-700 max-w-md">
            <h2 className="text-2xl font-bold mb-4">Pro</h2>
            <div className="text-3xl font-bold mb-6">
              €29<span className="text-xl text-gray-400">/month</span>
            </div>
            <ul className="space-y-4 mb-8">
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>100 AI generations per day</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>Everything in Basic</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>Up to 10 brands</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" size={16} />
                <span>Priority support</span>
              </li>
            </ul>
            <Button 
              className={getButtonClass('pro')}
              onClick={() => handlePlanAction(VARIANT_IDS.PRO, 'pro')}
              disabled={isCheckoutLoading}
            >
              {isCheckoutLoading ? (
                <div className="flex items-center justify-center">
                  <span className="mr-2">Processing...</span>
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                </div>
              ) : (
                getButtonText('Pro')
              )}
            </Button>
          </div>
        </div>
      </div>

      <AlertDialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Cancel Subscription</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to cancel your {planToCancel} subscription? 
              You'll lose access to all premium features at the end of your current billing period.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel 
              onClick={() => setShowCancelDialog(false)}
              disabled={isCheckoutLoading}
            >
              Keep Subscription
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleCancelConfirmed}
              disabled={isCheckoutLoading}
              className="bg-red-500 hover:bg-red-600"
            >
              {isCheckoutLoading ? 'Cancelling...' : 'Yes, Cancel Subscription'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default PricingPage;