import { useState, useCallback, useRef } from 'react';
import { UseChatMessagesReturn, WebSocketMessage } from '../types';
import { dbService } from '../services/dbService';
import { debounce } from 'lodash';

interface UseChatMessagesProps {
    activeChatId: string | null;
    setFollowUpPrompts: (prompts: any[]) => void;
}

export const useChatMessages = ({ activeChatId, setFollowUpPrompts }: UseChatMessagesProps): UseChatMessagesReturn => {
    const [chatMessages, setChatMessages] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [streamingMessage, setStreamingMessage] = useState<string | null>(null);
    const lastMessageRef = useRef<string>('');
    const messageBufferRef = useRef<string>('');
    const latestMessageRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = useCallback(() => {
        if (latestMessageRef.current) {
            latestMessageRef.current.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'end'
            });
        }
    }, []);

    const fetchMessages = useCallback(async () => {
        if (!activeChatId) return;
        
        try {
            setIsLoading(true);
            const messages = await dbService.getChatMessages(activeChatId);
            setChatMessages(messages);
            setTimeout(scrollToBottom, 100);
        } catch (err) {
            console.error('Error fetching messages:', err);
            setError('Failed to load messages');
        } finally {
            setIsLoading(false);
        }
    }, [activeChatId, scrollToBottom]);

    const addMessage = useCallback((message: string) => {
        setChatMessages(prev => [...prev, message]);
        setTimeout(scrollToBottom, 100);
    }, [scrollToBottom]);

    const debouncedSetStreamingMessage = useCallback(
        debounce((content: string) => {
            if (content !== lastMessageRef.current) {
                lastMessageRef.current = content;
                setStreamingMessage(content);
                setTimeout(scrollToBottom, 50);
            }
        }, 100),
        [scrollToBottom]
    );

    const handleWebSocketMessage = useCallback((data: WebSocketMessage) => {
        console.log('Received WebSocket message:', data);

        switch (data.type) {
            case 'messageStart':
                if (data.chatId === activeChatId) {
                    console.log('Starting message stream');
                    messageBufferRef.current = '';
                    setStreamingMessage('');
                    lastMessageRef.current = '';
                }
                break;

            case 'messageChunk':
                if (data.chatId === activeChatId && data.content) {
                    console.log('Received chunk:', data.content);
                    messageBufferRef.current += data.content;
                    debouncedSetStreamingMessage(messageBufferRef.current);
                }
                break;

            case 'messageComplete':
                if (data.chatId === activeChatId) {
                    debouncedSetStreamingMessage.flush();
                    
                    // Clear streaming message first
                    setStreamingMessage(null);
                    
                    // Wait a bit before adding the complete message
                    setTimeout(() => {
                        if (messageBufferRef.current) {
                            addMessage(`AI: ${messageBufferRef.current}`);
                            messageBufferRef.current = '';
                            lastMessageRef.current = '';
                        }
                        setIsLoading(false);
                    }, 100);
                }
                break;

            case 'followUpPrompts':
                if (data.chatId === activeChatId && data.prompts) {
                    setFollowUpPrompts(data.prompts);
                }
                break;

            case 'marketAnalysisComplete':
                if (data.chatId === activeChatId && data.analysis) {
                    // Add market analysis as a special message type
                    addMessage(`MARKET_ANALYSIS: ${data.analysis}`);
                }
                break;

            case 'error':
                if (data.chatId === activeChatId) {
                    setError(data.error || 'An error occurred');
                    setStreamingMessage(null);
                    messageBufferRef.current = '';
                    lastMessageRef.current = '';
                    setIsLoading(false);
                }
                break;
        }
    }, [activeChatId, addMessage, debouncedSetStreamingMessage, setFollowUpPrompts]);

    return {
        chatMessages,
        setChatMessages,
        isLoading,
        setIsLoading,
        error,
        setError,
        fetchMessages,
        addMessage,
        handleWebSocketMessage,
        streamingMessage,
        latestMessageRef
    };
}; 