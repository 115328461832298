export const COUNTRY_CODES: { [key: string]: string } = {
  'at': 'Austria',
  'ar': 'Argentina',
  'au': 'Australia',
  'be': 'Belgium',
  'br': 'Brazil',
  'ca': 'Canada',
  'cl': 'Chile',
  'cn': 'China',
  'co': 'Colombia',
  'dk': 'Denmark',
  'fi': 'Finland',
  'fr': 'France',
  'de': 'Germany',
  'gr': 'Greece',
  'in': 'India',
  'id': 'Indonesia',
  'ie': 'Ireland',
  'it': 'Italy',
  'jp': 'Japan',
  'mx': 'Mexico',
  'nl': 'Netherlands',
  'nz': 'New Zealand',
  'no': 'Norway',
  'pe': 'Peru',
  'ph': 'Philippines',
  'pl': 'Poland',
  'pt': 'Portugal',
  'ru': 'Russia',
  'rs': 'Serbia',
  'sg': 'Singapore',
  'za': 'South Africa',
  'es': 'Spain',
  'se': 'Sweden',
  'ch': 'Switzerland',
  'th': 'Thailand',
  'tr': 'Turkey',
  'gb': 'United Kingdom',
  'us': 'United States',
  'uy': 'Uruguay',
  've': 'Venezuela',
  'vn': 'Vietnam'
};

// We can also add other constants here as needed
export const ASPECT_RATIO_TO_SIZE = {
  '1:1': '1024x1024',
  '16:9': '1820x1024',
  '9:16': '1024x1820',
  '4:3': '1365x1024',
  '3:4': '1024x1365',
  'vertical': '1024x1536',
  'horizontal': '1536x1024',
  'square': '1024x1024',
  'banner': '1820x1024',
  'story': '1024x1820'
}; 