import axios from 'axios';

const LEMON_SQUEEZY_API_URL = 'https://api.lemonsqueezy.com/v1';

const getApiKey = () => {
  const apiKey = process.env.REACT_APP_LEMON_SQUEEZY_API_KEY;
  if (!apiKey) {
    throw new Error('Lemon Squeezy API key not found');
  }
  return apiKey;
};

const getLemonSqueezyHeaders = () => ({
  'Accept': 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
  'Authorization': `Bearer ${getApiKey()}`
});

export const createCheckout = async (variantId: string, email: string, userId: string) => {
  try {
    console.log('Creating checkout with:', { variantId, email, userId });
    
    // Real checkout URLs
    const BASIC_CHECKOUT_URL = 'https://adout.lemonsqueezy.com/buy/20eaa45f-d6f5-432e-9758-1e2296ca5fd8';
    const PRO_CHECKOUT_URL = 'https://adout.lemonsqueezy.com/buy/db57f480-7651-4c98-95f7-5a113a9a4130';

    // Add custom data to URL
    const checkoutUrl = new URL(variantId === '586439' ? BASIC_CHECKOUT_URL : PRO_CHECKOUT_URL);
    checkoutUrl.searchParams.append('checkout[email]', email);
    checkoutUrl.searchParams.append('checkout[custom][user_id]', userId);
    checkoutUrl.searchParams.append('checkout[success_url]', `${window.location.origin}/settings?success=true`);
    checkoutUrl.searchParams.append('checkout[cancel_url]', `${window.location.origin}/pricing?canceled=true`);

    const finalUrl = checkoutUrl.toString();
    console.log('Generated checkout URL:', finalUrl);
    
    // Return the URL first, let the component handle the redirect
    return { data: { attributes: { url: finalUrl } } };
  } catch (error) {
    console.error('Error creating checkout:', error);
    throw error;
  }
};

export const cancelSubscription = async (subscriptionId: string) => {
  try {
    console.log('Starting subscription cancellation:', subscriptionId);
    
    const headers = getLemonSqueezyHeaders();
    console.log('Using headers:', headers);
    
    const response = await axios.delete(
      `${LEMON_SQUEEZY_API_URL}/subscriptions/${subscriptionId}`,
      { 
        headers: getLemonSqueezyHeaders(),
        validateStatus: (status) => {
          console.log('Response status:', status);
          return status < 500; // Don't throw for any status < 500
        }
      }
    );

    console.log('Cancellation response:', response.data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error('Detailed cancellation error:', {
        message: error.message,
        response: (error as any).response?.data,
        status: (error as any).response?.status
      });
    } else {
      console.error('Unknown error occurred during cancellation');
    }
    throw error;
  }
};

// Helper to check if a user is on a specific plan
export const isOnPlan = (currentPlan: string | undefined, planType: 'basic' | 'pro'): boolean => {
  return currentPlan === planType;
};

// Helper to get button text based on plan status
export const getButtonText = (
  currentPlan: string | undefined, 
  planType: 'basic' | 'pro', 
  isLoading: boolean = false
): string => {
  if (isLoading) return 'Processing...';
  if (isOnPlan(currentPlan, planType)) return 'Cancel Plan';
  return `Select ${planType.charAt(0).toUpperCase() + planType.slice(1)} Plan`;
};

// Helper to handle both subscription and cancellation
export const handleSubscriptionAction = async (
  currentPlan: string | undefined,
  planType: 'basic' | 'pro',
  subscriptionId: string | undefined,
  email: string,
  userId: string
) => {
  if (isOnPlan(currentPlan, planType) && subscriptionId) {
    // Cancel current subscription
    return await cancelSubscription(subscriptionId);
  } else {
    // Create new subscription
    const variantId = planType === 'basic' ? '586439' : '586481';
    return await createCheckout(variantId, email, userId);
  }
};

export const getSubscription = async (subscriptionId: string) => {
  try {
    const response = await axios.get(
      `${LEMON_SQUEEZY_API_URL}/subscriptions/${subscriptionId}`,
      { headers: getLemonSqueezyHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    throw error;
  }
};

export const getCurrentSubscription = async (subscriptionId: string) => {
  try {
    const response = await axios.get(
      `${LEMON_SQUEEZY_API_URL}/subscriptions/${subscriptionId}`,
      {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `Bearer ${getApiKey()}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    throw error;
  }
};

export const TEST_VARIANT_IDS = {
  BASIC: 'test_basic_variant_id',
  PRO: 'test_pro_variant_id'
};

export const createTestCheckout = async (email: string) => {
  try {
    const response = await axios.post(
      'https://api.lemonsqueezy.com/v1/test-mode/checkouts',
      {
        data: {
          type: 'checkouts',
          attributes: {
            checkout_data: {
              email,
              test_mode: true
            },
            product_options: {
              enabled_variants: [TEST_VARIANT_IDS.BASIC]
            }
          }
        }
      },
      { headers: getLemonSqueezyHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating test checkout:', error);
    throw error;
  }
}; 