import React from 'react';
import { ChatInputProps } from '../types';
import { Send as SendIcon } from '@mui/icons-material';
import { Button } from "./ui/button";
import { FollowUpPrompt } from '../types';
import { Card } from "./ui/card";
import { DiamondOutlined } from '@mui/icons-material';

interface ExtendedChatInputProps extends ChatInputProps {
  isLimitReached: boolean;
  currentUsage: number | null;
  usageLimit: number | null;
  userPlan: 'free' | 'basic' | 'pro';
  onPricingClick: () => void;
}

const ChatInput: React.FC<ExtendedChatInputProps> = ({
  chatInput,
  setChatInput,
  handleChatSubmit,
  isLoading,
  followUpPrompts,
  onPromptClick,
  handleKeyPress,
  isLimitReached,
  currentUsage,
  usageLimit,
  userPlan,
  onPricingClick
}) => {
  // Helper function to determine next plan and its limit
  const getNextPlanInfo = (currentPlan: 'free' | 'basic' | 'pro'): { plan: 'basic' | 'pro', limit: number } => {
    if (currentPlan === 'free') {
      return { plan: 'basic', limit: 30 };
    }
    return { plan: 'pro', limit: 100 };
  };

  if (isLimitReached) {
    const { plan: nextPlan, limit: nextPlanLimit } = getNextPlanInfo(userPlan);

    return (
      <div className="chat-input-container bg-black bg-opacity-70 p-4 w-full">
        <div className="relative w-full flex items-center">
          <div className="flex-grow relative mr-2">
            <div 
              className="bg-gray-800 bg-opacity-70 w-full p-3 rounded-lg"
              style={{
                height: '90px',
                overflow: 'auto',
              }}
            >
              <Card className="h-full bg-gray-900 flex items-center justify-between p-4">
                <div className="flex items-center space-x-4">
                  <div className="text-left">
                    <h3 className="text-sm font-semibold text-white">
                      Daily Limit Reached ({currentUsage}/{usageLimit})
                    </h3>
                    <p className="text-xs text-gray-400">
                      Upgrade to {nextPlan} for {nextPlanLimit} messages/day
                    </p>
                  </div>
                </div>

                <Button 
                  onClick={onPricingClick}
                  className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white px-4 py-1 rounded-full transition-all transform hover:scale-105 text-xs h-8"
                >
                  <DiamondOutlined className="mr-1 w-4 h-4" />
                  Upgrade Plan
                </Button>
              </Card>
            </div>
          </div>
          <button 
            className="bg-gray-800 bg-opacity-70 w-[60px] h-[60px] rounded-full flex items-center justify-center opacity-50"
            disabled={true}
          >
            <SendIcon style={{ fontSize: '24px' }} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-input-container bg-black bg-opacity-70 p-4 w-full">
      <div className="relative w-full flex items-center">
        <div className="flex-grow relative mr-2">
          <textarea
            placeholder="Type your message..."
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={handleKeyPress}
            className="bg-gray-800 bg-opacity-70 text-white w-full p-3 rounded-lg resize-none text-[0.85rem]"
            style={{
              height: '90px',
              overflow: 'auto',
            }}
          />
          {chatInput === '' && followUpPrompts.length > 0 && (
            <div className="absolute bottom-3 left-3 right-3 flex flex-wrap gap-1 z-10">
              {followUpPrompts.map((prompt, index) => (
                <Button
                  key={index}
                  variant="outline"
                  size="sm"
                  onClick={() => onPromptClick(prompt.text)}
                  className="text-[0.75rem] bg-transparent bg-opacity-50 text-muted-foreground border-gray-700 rounded-full py-0.5 px-2 h-6 mb-1"
                >
                  {prompt.label}
                </Button>
              ))}
            </div>
          )}
        </div>
        <button 
          className={`send-button ${chatInput && !isLoading ? 'active' : ''} bg-gray-800 bg-opacity-70 circle-lg w-[60px] h-[60px] flex items-center justify-center`}
          onClick={handleChatSubmit}
          disabled={!chatInput.trim() || isLoading}
        >
          <SendIcon style={{ fontSize: '24px' }} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;