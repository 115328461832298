import React, { useEffect, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Image as ImageIcon } from '@mui/icons-material';
import CreativeLoadingAnimation from './CreativeLoadingAnimation';

interface ImageContentProps {
  isGeneratingImage: boolean;
  isImageToolUsed: boolean;
  generatedImageUrl: string | null;
  onImageError: () => void;
}

const ImageContent: React.FC<ImageContentProps> = ({
  isGeneratingImage,
  isImageToolUsed,
  generatedImageUrl,
  onImageError
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  useEffect(() => {
    if (generatedImageUrl) {
      setImageLoaded(false);
      setShowDownloadButton(false);
    }
  }, [generatedImageUrl]);

  useEffect(() => {
    if (imageLoaded) {
      const timer = setTimeout(() => {
        setShowDownloadButton(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [imageLoaded]);

  const handleDownload = () => {
    if (generatedImageUrl) {
      window.open(generatedImageUrl, '_blank');
    }
  };

  // Show creative loading animation when image tool is being used
  if (isImageToolUsed) {
    return (
      <div className="image-frame flex items-center justify-center min-h-[400px] min-w-[400px] bg-transparent rounded-lg">
        <CreativeLoadingAnimation />
      </div>
    );
  }

  // Show placeholder when no image and not generating
  if (!generatedImageUrl) {
    return (
      <div className="image-placeholder flex flex-col items-center justify-center bg-transparent min-h-[400px]">
        <ImageIcon style={{ fontSize: 48, color: '#6b7280' }} />
        <p className="mt-4 text-gray-500 text-center">Your generated image will appear here.</p>
      </div>
    );
  }

  // Show image when available
  return (
    <div className="image-frame">
      <img
        src={generatedImageUrl}
        alt="Generated content"
        onLoad={() => setImageLoaded(true)}
        onError={(e) => {
          console.error('Error loading image:', e);
          setImageLoaded(false);
          onImageError();
        }}
        style={{ 
          maxWidth: '100%',
          height: 'auto',
          display: imageLoaded ? 'block' : 'none'
        }}
      />
      {showDownloadButton && imageLoaded && (
        <button className="download-button" onClick={handleDownload}>
          <span>Download</span>
          <DownloadIcon />
        </button>
      )}
    </div>
  );
};

export default ImageContent;
