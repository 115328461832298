import React from 'react';
import { Brand } from '../hooks/useBrand';
import { BrandCard } from './BrandCard';

interface BrandFormProps {
  brand: Brand;
  onUpdate: (updatedFields: Partial<Brand>) => void;
  userPlan?: 'free' | 'basic' | 'pro';
}

const BrandForm: React.FC<BrandFormProps> = ({ brand, onUpdate, userPlan = 'free' }) => {
  return (
    <div className="space-y-6">
      <BrandCard 
        brand={brand}
        onUpdate={(updatedBrand) => {
          // Pass the updated brand data to parent
          onUpdate(updatedBrand || {});
        }}
        userPlan={userPlan}
      />
    </div>
  );
};

export default BrandForm;