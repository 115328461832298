import React, { useEffect, useState, useRef } from 'react';
import '../styles/ImageGallery.css';

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Filter for unique image files only
  const uniqueImageFiles = Array.from(new Set(images)).filter(url => {
    const extension = url.split('.').pop()?.toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif'].includes(extension || '');
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setScrollPosition(prev => {
        if (containerRef.current && prev >= containerRef.current.scrollHeight / 3) {
          return 0;
        }
        return prev + 0.5;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  // Distribute unique images across three columns
  const columns: string[][] = [[], [], []];
  uniqueImageFiles.forEach((url, index) => {
    columns[index % 3].push(url);
  });

  return (
    <div className="image-gallery">
      <div 
        ref={containerRef}
        className="gallery-grid"
        style={{ 
          transform: `translateY(-${scrollPosition}px)`,
          transition: scrollPosition === 0 ? 'none' : 'transform 0.05s linear'
        }}
      >
        {columns.map((column, columnIndex) => (
          <div key={`column-${columnIndex}`} className="gallery-column">
            {column.map((url, index) => (
              <div key={`col${columnIndex}-${url}`} className="gallery-item">
                <img src={url} alt={`Gallery item ${columnIndex}-${index}`} loading="lazy" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery; 