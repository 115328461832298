import React from 'react';
import { Campaign } from '../hooks/useCampaign';
import { Product } from '../hooks/useProduct';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { ScrollArea } from "./ui/scroll-area";
import { locationOptions } from '../utils/locationData';

interface CampaignFormProps {
  campaign: Campaign;
  products: Product[];
  onUpdate: (updatedFields: Partial<Campaign>) => void;
  onDelete: () => Promise<void>;
}

export function CampaignForm({ campaign, products, onUpdate }: CampaignFormProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onUpdate({ ...campaign, [name]: value });
  };

  return (
    <form className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Campaign Name</label>
        <Input id="name" name="name" value={campaign.name} onChange={handleInputChange} />
      </div>
      <div>
        <label htmlFor="target_audience" className="block text-sm font-medium text-gray-700">Target Audience</label>
        <Input id="target_audience" name="target_audience" value={campaign.target_audience} onChange={handleInputChange} />
      </div>
      <div>
        <label htmlFor="insight" className="block text-sm font-medium text-gray-700">Insight</label>
        <Input id="insight" name="insight" value={campaign.insight} onChange={handleInputChange} />
      </div>
      <div>
        <label htmlFor="creative_concept" className="block text-sm font-medium text-gray-700">Creative Concept</label>
        <Input id="creative_concept" name="creative_concept" value={campaign.creative_concept} onChange={handleInputChange} />
      </div>
      <div>
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
        <Select 
          value={campaign.location || 'global'} 
          onValueChange={(value) => onUpdate({ ...campaign, location: value })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select location" />
          </SelectTrigger>
          <SelectContent>
            <ScrollArea className="h-[200px]">
              {locationOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </ScrollArea>
          </SelectContent>
        </Select>
      </div>
      <div>
        <label htmlFor="product_id" className="block text-sm font-medium text-gray-700">Associated Product</label>
        <Select 
          value={campaign.product_id || ''} 
          onValueChange={(value) => onUpdate({ ...campaign, product_id: value })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select a product" />
          </SelectTrigger>
          <SelectContent>
            {products.map((product) => (
              <SelectItem key={product.id} value={product.id}>{product.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </form>
  );
}