import React, { useState } from 'react';
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";
import { 
  Upload, 
  X as XIcon,
  AlertCircle,
  Check,
  Loader2
} from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { compressImage } from '../utils/imageCompression';

interface Brand {
  id: string;
  name: string;
  logo_url?: string;
  style_image1_url?: string;
  style_image2_url?: string;
  style_image3_url?: string;
  style_image4_url?: string;
  is_trained: boolean;
  use_brand_style: boolean;
  use_logo: boolean;
  website?: string;
  style_id?: string;
  style_updated_at?: string;
}

interface BrandCardProps {
  brand: Brand;
  onUpdate: (brand: Partial<Brand>) => void;
  userPlan: 'free' | 'basic' | 'pro';
}

export const BrandCard: React.FC<BrandCardProps> = ({ brand, onUpdate, userPlan }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isUploading, setIsUploading] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isRemovingBackground, setIsRemovingBackground] = useState(false);
  const [logoTimestamp, setLogoTimestamp] = useState<number>(Date.now());

  console.log('BrandCard received userPlan:', userPlan);

  const handleStyleImageUpload = async (file: File, imageNumber: number) => {
    try {
      setIsUploading(true);
      setError(null);
      
      const formData = new FormData();
      formData.append('image', file);
      
      const token = await getAccessTokenSilently();
      console.log('Uploading image:', { imageNumber, fileSize: file.size });
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}/upload-style-image/${imageNumber}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      console.log('Upload response:', response.data);

      onUpdate(response.data);
    } catch (error) {
      console.error('Error uploading style image:', error);
      setError('Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleTrainModel = async () => {
    // Get all style image URLs
    const styleImages = [
        brand.style_image1_url,
        brand.style_image2_url,
        brand.style_image3_url,
        brand.style_image4_url
    ].filter(url => url); // Filter out undefined/null values

    if (styleImages.length < 3) {
        setError('Please upload at least three style images');
        return;
    }

    try {
        setIsTraining(true);
        setError(null);
        
        const token = await getAccessTokenSilently();
        console.log('Starting style training with images:', styleImages);
        
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}/train-style`,
            { imageUrls: styleImages },
            {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        console.log('Style training response:', response.data);
        
        if (response.data.styleId) {
            // Update brand with new style ID and status
            onUpdate({
                ...brand,
                style_id: response.data.styleId,
                is_trained: true,
                use_brand_style: true,
                style_updated_at: new Date().toISOString()
            });
        } else {
            throw new Error('No style ID received from training');
        }

    } catch (error: any) {
        console.error('Error training brand style:', error);
        setError(error.response?.data?.error || 'Failed to train brand style');
    } finally {
        setIsTraining(false);
    }
};

  const handleToggleUseStyle = async () => {
    if (userPlan === 'free') return;

    try {
      console.log('Toggling brand style from:', brand.use_brand_style, 'to:', !brand.use_brand_style);
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}`,
        { use_brand_style: !brand.use_brand_style },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      console.log('Toggle response:', response.data);
      onUpdate(response.data);
    } catch (error) {
      console.error('Error toggling brand style:', error);
      setError('Failed to update settings');
    }
  };

  const handleToggleUseLogo = async () => {
    if (userPlan === 'free') return;

    try {
      console.log('Toggling logo usage from:', brand.use_logo, 'to:', !brand.use_logo);
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}`,
        { use_logo: !brand.use_logo },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      console.log('Toggle response:', response.data);
      onUpdate(response.data);
    } catch (error) {
      console.error('Error toggling logo usage:', error);
      setError('Failed to update settings');
    }
  };

  const handleDeleteImage = async (imageNumber: number) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}/style-image/${imageNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      onUpdate(response.data);
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image');
    }
  };

  const handleLogoUpload = async (file: File) => {
    try {
      setIsUploading(true);
      setIsRemovingBackground(true);
      setError(null);
      
      const formData = new FormData();
      formData.append('file', file);
      
      const token = await getAccessTokenSilently();
      
      // First upload
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}/upload-logo`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      // Show original logo while processing
      onUpdate({ ...brand, logo_url: uploadResponse.data.logo_url });
      
      // Remove background
      const removeBackgroundResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/brands/${brand.id}/remove-logo-background`,
        { imageUrl: uploadResponse.data.logo_url },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      // Force image refresh with a random timestamp
      const processedUrl = `${removeBackgroundResponse.data.processedImageUrl}?t=${Date.now()}`;
      onUpdate({ ...brand, logo_url: processedUrl });

      // Force a re-render of the image
      setLogoTimestamp(Date.now());

    } catch (error) {
      console.error('Error uploading/processing logo:', error);
      setError('Failed to upload or process logo');
    } finally {
      setIsUploading(false);
      setIsRemovingBackground(false);
    }
  };

  const canUseStyle = userPlan !== 'free' && brand.is_trained;
  const canUseLogo = userPlan !== 'free' && brand.logo_url;

  const getUploadedImageCount = () => {
    return [1, 2, 3, 4].reduce((count, num) => {
      const imageUrl = brand[`style_image${num}_url` as keyof Brand];
      return imageUrl ? count + 1 : count;
    }, 0);
  };

  const getTrainButtonTooltip = () => {
    const uploadedCount = getUploadedImageCount();
    if (brand.is_trained) {
      return "Model is already trained";
    }
    if (uploadedCount < 3) {
      return `Upload at least 3 style images (${uploadedCount}/3)`;
    }
    return "Train model with your style images";
  };

  return (
    <Card className="p-4 space-y-6 bg-black border border-[#333]">
      {/* Brand Name */}
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-400 mb-2">Brand Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={brand.name}
          onChange={(e) => {
            const updatedBrand = { ...brand, name: e.target.value };
            onUpdate(updatedBrand);
          }}
          className="w-full px-3 py-2 bg-gray-800 border border-[#333] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white"
        />
      </div>

      {/* Logo Section */}
      <div className="space-y-2">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h3 className="text-sm font-medium text-gray-400">Logo</h3>
            <p className="text-sm text-gray-500">Logo will be automatically placed in your generated images</p>
          </div>
          <div className="flex items-center space-x-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center space-x-2">
                    <div className="transform scale-[0.5] origin-right">
                      <Switch
                        checked={brand.use_logo}
                        onCheckedChange={handleToggleUseLogo}
                        disabled={!canUseLogo}
                      />
                    </div>
                    <span className="text-sm text-gray-400">Use Logo</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {userPlan === 'free' 
                    ? 'Upgrade to use logo'
                    : !brand.logo_url
                    ? 'Upload logo first'
                    : 'Toggle logo usage'
                  }
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="w-24 aspect-square relative">
          {brand.logo_url ? (
            <div className="relative w-full h-full">
              <img 
                src={`${brand.logo_url}?t=${logoTimestamp}`}
                alt="Brand Logo" 
                className="w-full h-full object-contain rounded border border-gray-200"
              />
              {isRemovingBackground && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded">
                  <Loader2 className="w-6 h-6 text-white animate-spin" />
                </div>
              )}
              <button
                onClick={() => handleDeleteImage(0)}
                className="absolute top-1 right-1 p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
              >
                <XIcon className="w-3 h-3 text-white" />
              </button>
            </div>
          ) : (
            <label className="flex items-center justify-center w-full h-full border-2 border-dashed rounded cursor-pointer hover:bg-gray-50/5 transition-colors">
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(e) => e.target.files && handleLogoUpload(e.target.files[0])}
              />
              <Upload className="w-4 h-4 text-gray-400" />
            </label>
          )}
        </div>
      </div>

      {/* Style Images Section */}
      <div className="space-y-2">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h3 className="text-sm font-medium text-gray-400">Brand style</h3>
            <p className="text-sm text-gray-500">Upload at least 3 images that represent your brand's visual style</p>
          </div>
          <div className="flex items-center space-x-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center space-x-2">
                    <div className="transform scale-[0.5] origin-right">
                      <Switch
                        checked={brand.use_brand_style}
                        onCheckedChange={handleToggleUseStyle}
                        disabled={!canUseStyle}
                      />
                    </div>
                    <span className="text-sm text-gray-400">Use Style</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {userPlan === 'free' 
                    ? 'Upgrade to use brand style'
                    : !brand.is_trained
                    ? 'Train model first'
                    : 'Toggle style usage'
                  }
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="flex gap-2">
          {[1, 2, 3, 4].map((num) => {
            const imageUrl = brand[`style_image${num}_url` as keyof Brand];
            return (
              <div key={num} className="w-24 aspect-square relative">
                {imageUrl ? (
                  <div className="relative w-full h-full">
                    <img 
                      src={imageUrl as string} 
                      alt={`Style ${num}`}
                      className="w-full h-full object-cover rounded border border-gray-200"
                    />
                    <button
                      onClick={() => handleDeleteImage(num)}
                      className="absolute top-1 right-1 p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                    >
                      <XIcon className="w-3 h-3 text-white" />
                    </button>
                  </div>
                ) : (
                  <label className="flex items-center justify-center w-full h-full border-2 border-dashed rounded cursor-pointer hover:bg-gray-50/5 transition-colors">
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={async (e) => {
                        if (e.target.files) {
                          const compressedFile = await compressImage(e.target.files[0]);
                          handleStyleImageUpload(compressedFile, num);
                        }
                      }}
                    />
                    <Upload className="w-4 h-4 text-gray-400" />
                  </label>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Train Model Button */}
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="w-[calc(24rem+0.75rem)]">
              <Button
                onClick={handleTrainModel}
                disabled={isTraining || brand.is_trained || getUploadedImageCount() < 3}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white rounded-full transition-all transform hover:scale-105"
              >
                {isTraining ? 'Training...' : 'Train Adout for Brand Style'}
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            {getTrainButtonTooltip()}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      {/* Website Input */}
      <div>
        <label htmlFor="website" className="block text-sm font-medium text-gray-400 mb-2">Website URL</label>
        <input
          type="url"
          id="website"
          name="website"
          value={brand.website || ''}
          onChange={(e) => {
            const updatedBrand = { ...brand, website: e.target.value };
            onUpdate(updatedBrand);
          }}
          placeholder="https://example.com"
          className="w-full px-3 py-2 bg-gray-800 border border-[#333] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-white"
        />
      </div>

      {error && (
        <div className="text-red-500 text-sm flex items-center space-x-1">
          <AlertCircle className="w-4 h-4" />
          <span>{error}</span>
        </div>
      )}
    </Card>
  );
}; 