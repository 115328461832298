import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { Button } from "./ui/button";
import { Copy } from 'lucide-react';

interface AIResponseProps {
  content: string;
  isToolSummary?: boolean;
}

const AIResponse: React.FC<AIResponseProps> = ({ content, isToolSummary = false }) => {
  const filteredContent = content.trim();

  if (!filteredContent || filteredContent === "No tools were used in generating this response.") {
    return null;
  }

  return (
    <div className={`ai-response ${isToolSummary ? 'tool-summary' : ''}`}>
      <div className="prose prose-invert max-w-none">
        <ReactMarkdown>{filteredContent}</ReactMarkdown>
      </div>
      <div className="absolute bottom-1 right-1">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="p-1 h-5 w-5"
                onClick={() => navigator.clipboard.writeText(filteredContent)}
              >
                <Copy className="h-3 w-3" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="font-inter text-xs">Copy response</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

export default AIResponse;