import React, { useState, useEffect } from 'react';
import { Product } from '../hooks/useProduct';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Upload, X } from 'lucide-react';
import axios from 'axios';

interface ProductFormProps {
  product: Product;
  onUpdate: (updatedFields: Partial<Product>) => void;
}

export function ProductForm({ product, onUpdate }: ProductFormProps) {
  const [localProduct, setLocalProduct] = useState<Product>(product);

  useEffect(() => {
    setLocalProduct(product);
  }, [product]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedProduct = { ...localProduct, [name]: value };
    setLocalProduct(updatedProduct);
    onUpdate(updatedProduct);
  };

  const handleImageUpload = async (index: number, file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('index', index.toString());

      const response = await axios.post(`/api/products/${product.id}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const imageUrl = response.data[`image_url${index}`];
      const updatedProduct = { ...localProduct, [`image_url${index}`]: imageUrl };
      setLocalProduct(updatedProduct);
      onUpdate(updatedProduct);
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error (show notification, etc.)
    }
  };

  const handleImageRemove = (index: number) => {
    const updatedProduct = { ...localProduct, [`image_url${index}`]: undefined };
    setLocalProduct(updatedProduct);
    onUpdate(updatedProduct);
  };

  const renderImageUpload = (index: number) => {
    const imageUrl = localProduct[`image_url${index}` as keyof Product] as string;
    return (
      <div key={index} className="image-upload-item relative">
        {imageUrl ? (
          <div className="image-preview">
            <img src={imageUrl} alt={`Product ${index}`} className="product-image w-24 h-24 object-cover rounded" />
            <Button
              variant="destructive"
              size="icon"
              className="absolute top-0 right-0 bg-red-500 p-1 rounded-full"
              onClick={() => handleImageRemove(index)}
            >
              <X size={16} />
            </Button>
          </div>
        ) : (
          <label className="flex flex-col items-center justify-center w-24 h-24 border-2 border-dashed border-gray-300 rounded cursor-pointer">
            <Upload size={24} />
            <span>Upload</span>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleImageUpload(index, file);
                }
              }}
              className="hidden"
            />
          </label>
        )}
      </div>
    );
  };

  return (
    <form className="product-form space-y-4 mt-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Product Name</label>
        <Input
          id="name"
          name="name"
          value={localProduct.name}
          onChange={handleChange}
          className="mt-1"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Product Images</label>
        <div className="image-upload-container flex space-x-4 mt-1">
          {[1, 2, 3].map(index => renderImageUpload(index))}
        </div>
      </div>
    </form>
  );
}