import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { Send as SendIcon, Download as DownloadIcon } from '@mui/icons-material';
import PulsatingCircles from './PulsatingCircles';
import CreativeLoadingAnimation from './CreativeLoadingAnimation';
import Header from './Header';
import '../styles/ChatPage.css';
import CustomAlertDialog from './ui/CustomAlertDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ExamplePromptsCard from './ExamplePromptsCard';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card"
import { Input } from "../components/ui/input"
import { Button } from "../components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import OnboardingModal from './OnboardingModal';
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "./ui/collapsible";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { ChevronDown, ChevronUp, Copy } from 'lucide-react';
import CampaignInfo from './CampaignInfo';
import ImageContent from './ImageContent';
import ChatInput from './ChatInput';
import AIResponse from './AIResponse';
import { FollowUpPrompt, ChatResponse } from '../types';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Image as ImageIcon } from '@mui/icons-material';
import LoadingSpinner from './LoadingSpinner';
import { PLAN_LIMITS } from '../utils/planLimits';
import MarketAnalysis from './MarketAnalysis';
import { useChatMessages } from '../hooks/useChatMessages';
import { useChatWebSocket } from '../hooks/useChatWebSocket';
import StreamingLines from './StreamingLines';

interface ChatPageProps {
  initialDescription: string;
  chatMessages: string[];
  setChatMessages: React.Dispatch<React.SetStateAction<string[]>>;
  generatedImageUrl: string | null;
  setGeneratedImageUrl: React.Dispatch<React.SetStateAction<string | null>>;
  isGeneratingImage: boolean;
  setIsGeneratingImage: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessage: (message: string, aspectRatio: string, chatId?: string | null, imageKey?: string | null) => Promise<ChatResponse>;
  isAuthenticated: boolean;
  user: any;
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onPricingClick: () => void;
  latestCampaign: any;
  currentBrand: any;
  forceUpdate: number;
  imageKey: string | null;
  setImageKey: React.Dispatch<React.SetStateAction<string | null>>;
  aspectRatio: string;
  followUpPrompts: FollowUpPrompt[];
  setFollowUpPrompts: React.Dispatch<React.SetStateAction<FollowUpPrompt[]>>;
  isUsingTool: boolean;
  currentTool: string | null;
  initialChatId: string | null;
  setCurrentChatId: React.Dispatch<React.SetStateAction<string | null>>;
  userPlan: 'free' | 'basic' | 'pro';
}

interface Chat {
  id: number;
  created_at: string;
  chatName: string;
  summary: string;
}

interface ChatMessage {
  id: number;
  message: string;
  is_user: boolean;
  created_at: string;
}

interface UsageStats {
  daily_tool_chat_count: number;
  monthly_brand_training_count: number;
  monthly_product_training_count: number;
  last_tool_chat_reset: string;
  last_monthly_reset: string;
}

// Add a new type for market analysis
interface MarketAnalysisData {
    content: string;
    isComplete: boolean;
}

const ChatPage = forwardRef<HTMLDivElement, ChatPageProps>((props, ref) => {
  const {
    initialDescription,
    sendMessage,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    onSettingsClick,
    onPricingClick,
    latestCampaign,
    currentBrand,
    aspectRatio,
    setCurrentChatId,
    userPlan,
    initialChatId,
    generatedImageUrl,
    setGeneratedImageUrl,
    isGeneratingImage,
    setIsGeneratingImage,
    imageKey,
    setImageKey,
    followUpPrompts,
    setFollowUpPrompts,
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { chatId: urlChatId } = useParams<{ chatId?: string }>();
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // Local state that isn't handled by hooks
  const [activeChatId, setActiveChatId] = useState<string | null>(urlChatId || initialChatId);
  const [chats, setChats] = useState<Chat[]>([]);
  const [chatInput, setChatInput] = useState('');
  const [chatName, setChatName] = useState('');
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [isUsingToolState, setIsUsingToolState] = useState(false);
  const [currentToolState, setCurrentToolState] = useState<string | null>(null);
  const [forceUpdateState, setForceUpdateState] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [usageStats, setUsageStats] = useState<any>(null);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [currentUsage, setCurrentUsage] = useState<number | null>(null);
  const [usageLimit, setUsageLimit] = useState<number | null>(null);
  const [userPlanType, setUserPlanType] = useState<'free' | 'basic' | 'pro'>('free');
  const [marketAnalysisData, setMarketAnalysisData] = useState<MarketAnalysisData>({
    content: '',
    isComplete: false
  });
  const [completedAnalyses, setCompletedAnalyses] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Use our custom hooks
  const {
    chatMessages,
    setChatMessages,
    isLoading,
    setIsLoading,
    error,
    setError,
    fetchMessages,
    addMessage,
    handleWebSocketMessage,
    streamingMessage,
    latestMessageRef
  } = useChatMessages({ 
    activeChatId, 
    setFollowUpPrompts
  });

  const {
    socket,
    isStreaming,
    streamingContent,
    setStreamingContent,
    setIsStreaming
  } = useChatWebSocket({
    activeChatId,
    onMessageReceived: handleWebSocketMessage,
    setIsGeneratingImage: props.setIsGeneratingImage,
    setGeneratedImageUrl: props.setGeneratedImageUrl,
    setImageKey: props.setImageKey,
    setIsUsingToolState,
    setCurrentToolState,
    setFollowUpPrompts: props.setFollowUpPrompts,
    addMessage,
    requestToolSummary: async (chatId: string) => {
        if (!chatId) return;
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/tool-summary`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) throw new Error('Failed to request tool summary');
        } catch (error) {
            console.error('Error requesting tool summary:', error);
        }
    }
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const chatIdToUse = urlChatId || initialChatId;
    if (chatIdToUse && !isFetching && chatIdToUse !== activeChatId) {
      console.log('Setting active chat ID:', chatIdToUse);
      setActiveChatId(chatIdToUse);
      
      if (!location.state?.skipFetch) {
        fetchChatMessages(chatIdToUse);
      }
    }
  }, [urlChatId, initialChatId, isFetching, activeChatId, location.state?.skipFetch]);

  const fetchChatData = async (chatId: string) => {
    try {
      console.log('Fetching chat data for:', chatId);
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/chats/${chatId}?includeImage=true`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('Received chat data:', response.data);
      
      // Set chat messages
      if (response.data.messages) {
        setChatMessages(response.data.messages.map((msg: any) => 
          `${msg.is_user ? 'User: ' : 'AI: '}${msg.message}`
        ));
      }

      // Set image if exists
      if (response.data.generatedImageUrl) {
        setGeneratedImageUrl(response.data.generatedImageUrl);
      }

      // Set chat name
      if (response.data.chatName) {
        setChatName(response.data.chatName);
      }
    } catch (error) {
      console.error('Error fetching chat data:', error);
      setError('Failed to load chat history. Please try again.');
    }
  };

  const fetchUsageStats = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/usage`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('Fetched usage stats:', response.data);
      setUsageStats(response.data);
    } catch (error) {
      console.error('Error fetching usage stats:', error);
    }
  };

  // Initial fetch of usage stats
  useEffect(() => {
    if (isAuthenticated) {
      fetchUsageStats();
    }
  }, [isAuthenticated]);

  const generateImageKey = () => {
    return `img_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleChatSubmit = async () => {
    if (!chatInput.trim() || isLoading) return;

    const userMessage = chatInput;
    setChatInput('');
    
    // Add user message immediately without refreshing the whole list
    addMessage(`User: ${userMessage}`);

    try {
        setIsLoading(true);
        setError(null);

        // Generate new imageKey if none exists
        const currentImageKey = imageKey || generateImageKey();
        setImageKey(currentImageKey);

        const response = await sendMessage(userMessage, aspectRatio, activeChatId, currentImageKey);
        
        // Add market analysis if present
        if (response.marketAnalysis) {
            addMessage(`MARKET_ANALYSIS:${JSON.stringify(response.marketAnalysis)}`);
        }

        if (response.imageUrl) {
            setGeneratedImageUrl(response.imageUrl);
        }
        if (response.followUpPrompts) {
            setFollowUpPrompts(response.followUpPrompts);
        }
        if (response.chatName) {
            setChatName(response.chatName);
        }
        if (response.chatId) {
            setActiveChatId(response.chatId.toString());
            navigate(`/chat/${response.chatId}`);
        }

        // Update usage stats after successful message
        setUsageStats((prev: UsageStats) => ({
            ...prev,
            daily_tool_chat_count: (prev?.daily_tool_chat_count || 0) + 1
        }));

    } catch (err: any) {
        console.error('Error sending message:', err);
        
        if (err.response?.status === 403 && err.response?.data?.upgradePlan) {
            setIsLimitReached(true);
            setCurrentUsage(err.response.data.current);
            setUsageLimit(err.response.data.limit);
            setChatInput(userMessage); // Keep the unsent message
            return;
        }
        
        setError('An error occurred while sending the message. Please try again.');
    } finally {
        setIsLoading(false);
    }
  };

  const handleLogin = () => {
    localStorage.setItem('loginRedirectPath', '/chat');
    if (chatMessages.length > 0) {
      localStorage.setItem('unauthenticatedChatMessages', JSON.stringify(chatMessages));
      if (generatedImageUrl) {
        localStorage.setItem('unauthenticatedGeneratedImageUrl', generatedImageUrl);
      }
    }
    loginWithRedirect();
  };

  useEffect(() => {
    if (isAuthenticated) {
      const savedMessages = localStorage.getItem('unauthenticatedChatMessages');
      const savedImageUrl = localStorage.getItem('unauthenticatedGeneratedImageUrl');
      if (savedMessages) {
        setChatMessages(JSON.parse(savedMessages));
        localStorage.removeItem('unauthenticatedChatMessages');
      }
      if (savedImageUrl) {
        setGeneratedImageUrl(savedImageUrl);
        localStorage.removeItem('unauthenticatedGeneratedImageUrl');
      }
      setIsOnboardingOpen(false);
    }
  }, [isAuthenticated, setChatMessages, setGeneratedImageUrl]);

  const handleOnboardingComplete = (sessionData: any) => {
    setIsOnboardingOpen(false);
    // Handle the completion of onboarding
    console.log('Onboarding completed:', sessionData);
  };

  const handleInitialDescription = (description: string) => {
    // Handle the initial description
    console.log('Initial description:', description);
    // You might want to update the chat or start a new one with this description
  };

  const handleSelectPrompt = (text: string) => {
    setChatInput(text);
  };

  const handlePromptClick = (text: string) => {
    setChatInput(text);
    setFollowUpPrompts([]);
  };

  const renderCampaignInfo = (campaignInfo: string) => {
    return (
      <CampaignInfo
        campaignInfo={campaignInfo}
        latestCampaign={latestCampaign}
        currentBrand={currentBrand}
        setChatMessages={setChatMessages}
        onSettingsClick={onSettingsClick}
      />
    );
  };

  const renderUserMessage = (message: string, index: number) => {
    const content = message.replace(/^User: /, '').trim();
    return (
      <div key={index} className="chat-message user-message bg-gray-800 bg-opacity-70 p-3 rounded-lg mb-2 flex items-center">
        <Avatar className="w-6 h-6 mr-2 flex-shrink-0">
          <AvatarImage src={user?.picture} alt={user?.name} />
          <AvatarFallback>{user?.name?.charAt(0) || 'U'}</AvatarFallback>
        </Avatar>
        <div className="flex-grow">{content}</div>
      </div>
    );
  };

  const handleImageError = useCallback(async () => {
    console.error('Error loading image:', generatedImageUrl);
    
    if (activeChatId) {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${activeChatId}/image`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.imageUrl && data.imageUrl !== generatedImageUrl) {
            setGeneratedImageUrl(data.imageUrl);
          }
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  }, [generatedImageUrl, activeChatId, getAccessTokenSilently, setGeneratedImageUrl]);

  const renderImageContent = () => {
    return (
      <ImageContent
        isGeneratingImage={isGeneratingImage}
        isImageToolUsed={isUsingToolState && currentToolState === 'image'}
        generatedImageUrl={generatedImageUrl}
        onImageError={() => {
          setGeneratedImageUrl(null);
        }}
      />
    );
  };

  const startNewChat = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chats`, 
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Store chatId in localStorage
      localStorage.setItem('currentChatId', response.data.chatId);
      setActiveChatId(response.data.chatId);
      setChatMessages([]);
      setGeneratedImageUrl(null);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  // Add this useEffect to fetch chat list when the component mounts
  useEffect(() => {
    const fetchChatsIfAuthenticated = async () => {
      if (isAuthenticated) {
        try {
          await fetchChatList();
        } catch (error) {
          console.error('Error in fetchChatsIfAuthenticated:', error);
        }
      }
    };

    fetchChatsIfAuthenticated();
  }, [isAuthenticated]);

  useEffect(() => {
    if (activeChatId && activeChatId !== props.initialChatId) {
      console.log('Updating parent currentChatId:', activeChatId);
      props.setCurrentChatId(activeChatId);
      localStorage.setItem('currentChatId', activeChatId);
    }
  }, [activeChatId]);

  // Fetch chat data when activeChatId changes
  useEffect(() => {
    const fetchChatData = async (chatId: string) => {
      try {
        console.log('Fetching chat data for:', chatId);
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/chats/${chatId}?includeImage=true`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        console.log('Received chat data:', response.data);
        
        // Set chat messages
        if (response.data.messages) {
          setChatMessages(response.data.messages.map((msg: any) => 
            `${msg.is_user ? 'User: ' : 'AI: '}${msg.message}`
          ));
        }

        // Set image if exists
        if (response.data.generatedImageUrl) {
          setGeneratedImageUrl(response.data.generatedImageUrl);
        }

        // Set chat name
        if (response.data.chatName) {
          setChatName(response.data.chatName);
        }
      } catch (error) {
        console.error('Error fetching chat data:', error);
        setError('Failed to load chat history. Please try again.');
      }
    };

    if (activeChatId && !location.state?.skipFetch) {
      console.log('Active chat changed, fetching data for:', activeChatId);
      fetchChatData(activeChatId);
    }
  }, [activeChatId, getAccessTokenSilently]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const fetchUserPlan = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/me`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('User plan data:', response.data);
      setUserPlanType(response.data.plan_type || 'free');
    } catch (error) {
      console.error('Error fetching user plan:', error);
      setUserPlanType('free'); // Default to free if there's an error
    }
  };

  // Add this useEffect to fetch user plan when component mounts
  useEffect(() => {
    if (isAuthenticated) {
      fetchUserPlan();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Reset tool state when changing chats
    setIsUsingToolState(false);
    setCurrentToolState(null);
  }, [activeChatId]);

  const handleCloseStream = () => {
    setIsStreaming(false);
    setStreamingContent('');
  };

  const fetchChatList = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const data = await response.json();
        setChats(data);
      } else {
        console.error('Failed to fetch chat list');
      }
    } catch (error) {
      console.error('Error fetching chat list:', error);
      setError('Failed to fetch chat list. Please try again later.');
    }
  };

  const fetchChatMessages = async (chatId: string) => {
    if (isFetching || !chatId) return;
    
    try {
        console.log('Fetching messages for chat:', chatId);
        setIsFetching(true);
        const token = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}/messages`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Received chat messages:', data.messages);
        
        // Clear existing messages first
        setChatMessages([]);
        
        // Add messages one by one
        data.messages.forEach((msg: ChatMessage) => {
            if (msg.message.startsWith('Generated image:')) {
                const imageUrl = msg.message.replace('Generated image:', '').trim();
                setGeneratedImageUrl(imageUrl);
                return;
            }
            
            if (msg.message.startsWith('MARKET_ANALYSIS:')) {
                addMessage(msg.message); // Keep the original format
                return;
            }
            
            addMessage(msg.is_user ? `User: ${msg.message}` : `AI: ${msg.message}`);
        });
        
        // Set image URL if exists
        if (data.imageUrl) {
            setGeneratedImageUrl(data.imageUrl);
        }

        if (data.chatName) {
            setChatName(data.chatName);
        }
    } catch (error) {
        console.error('Error fetching chat messages:', error);
    } finally {
        setIsFetching(false);
    }
  };

  useEffect(() => {
    if (isStreaming && streamingContent) {
        // Always show streaming content as the latest message
        setChatMessages(prev => {
            // Create a new array with all previous messages
            const messages = [...prev];
            
            // If the last message is from AI and was added by streaming,
            // replace it with the new streaming content
            if (messages.length > 0 && messages[messages.length - 1].startsWith('AI:') && 
                !messages[messages.length - 1].startsWith('AI: MARKET_ANALYSIS:')) {
                messages[messages.length - 1] = `AI: ${streamingContent}`;
            } else {
                // Otherwise add as new message
                messages.push(`AI: ${streamingContent}`);
            }
            
            return messages;
        });
    }
  }, [isStreaming, streamingContent]);

  return (
    <ErrorBoundary>
      <div key={forceUpdateState} className="chat-page bg-black text-white" ref={ref}>
        <Header 
          page="chat" 
          onBackClick={() => navigate(-1)}
          isAuthenticated={isAuthenticated}
          user={user}
          loginWithRedirect={loginWithRedirect}
          logout={logout}
          onSettingsClick={() => navigate('/settings', { 
            state: { 
              previousPath: `/chat/${activeChatId}` 
            }
          })}
          onPricingClick={onPricingClick}
          chatName={chatName}
        />
        {isInitialLoading ? (
          <div className="flex-1 flex justify-center items-center min-h-[calc(100vh-64px)]">
            <LoadingSpinner />
          </div>
        ) : (
          <div className={`split-screen ${isMobile ? 'mobile' : ''}`}>
            {isMobile && (
              <div className="image-container">
                {renderImageContent()}
              </div>
            )}
            <div className="chat-container bg-black bg-opacity-70">
              {/* {renderChatList()} */}
              <div 
                className="chat-messages bg-black bg-opacity-70 pb-[100px]"
                ref={chatMessagesRef}
              >
                <ExamplePromptsCard onSelectPrompt={handleSelectPrompt} />
                {chatMessages.map((message, index) => {
                  // Skip hidden messages in display
                  if (message.startsWith('HIDDEN_IMAGE_DESCRIPTION:')) {
                    return null;
                  }
                  
                  if (message.startsWith('User:')) {
                    return renderUserMessage(message, index);
                  } else if (message.startsWith('AI:')) {
                    // Skip rendering the last AI message if we're currently streaming
                    if (isStreaming && index === chatMessages.length - 1) {
                        return null;
                    }
                    const content = message.replace(/^AI: /, '').trim();
                    return (
                        <React.Fragment key={`ai-message-${index}`}>
                            {content && (
                                <div className="chat-message ai-message bg-gray-900 bg-opacity-70 p-3 rounded-lg mb-1 mt-1 relative">
                                    <div className="text-[0.85rem]">
                                        <AIResponse content={content} isToolSummary={false} />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    );
                  } else if (message.startsWith('MARKET_ANALYSIS:')) {
                    const content = message.replace(/^MARKET_ANALYSIS: /, '').trim();
                    return (
                        <div key={`market-analysis-${index}`} className="chat-message market-analysis bg-gray-900 bg-opacity-70 p-3 rounded-lg mb-1 mt-1">
                            <div className="text-[0.85rem]">
                                <AIResponse content={content} isToolSummary={false} />
                            </div>
                        </div>
                    );
                  }
                  return null;
                })}
                {isStreaming && streamingContent && (
                    <StreamingLines 
                        content={streamingContent} 
                        isStreaming={isStreaming}
                    />
                )}
                {isLoading && <PulsatingCircles />}
                {error && <div className="error-message">{error}</div>}
              </div>
              <ChatInput
                chatInput={chatInput}
                setChatInput={setChatInput}
                handleChatSubmit={handleChatSubmit}
                isLoading={isLoading}
                handleKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleChatSubmit();
                  }
                }}
                followUpPrompts={followUpPrompts}
                onPromptClick={(text) => {
                    setChatInput(text);
                    setFollowUpPrompts([]);
                }}
                isLimitReached={isLimitReached}
                currentUsage={currentUsage}
                usageLimit={usageLimit}
                userPlan={userPlanType}
                onPricingClick={onPricingClick}
              />
            </div>
            {!isMobile && (
              <div className="image-container">
                {renderImageContent()}
              </div>
            )}
          </div>
        )}
      </div>
      <CustomAlertDialog
        isOpen={showLoginDialog}
        onOpenChange={setShowLoginDialog}
        title="Login Required"
        description="You need to be logged in to continue chatting. Would you like to log in now?"
        confirmText="Log In"
        onConfirm={() => {
          setShowLoginDialog(false);
          handleLogin();
        }}
      />
      <OnboardingModal
        isOpen={isOnboardingOpen}
        onClose={() => setIsOnboardingOpen(false)}
        productDescription={initialDescription}
        onComplete={handleOnboardingComplete}
      />
      {isUsingToolState && (
        <div className="tool-use-indicator">
          Using tool: {currentToolState}
        </div>
      )}
    </ErrorBoundary>
  );
});

export default ChatPage;
