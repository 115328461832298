import imageCompression from 'browser-image-compression';

export const compressImage = async (file: File) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
    preserveExif: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    console.log('Compression results:', {
      originalSize: file.size / 1024 / 1024,
      compressedSize: compressedFile.size / 1024 / 1024,
    });
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    throw error;
  }
}; 