import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { Send as SendIcon, DiamondOutlined } from '@mui/icons-material';
import '../styles/HomePage.css';
import OnboardingModal from './OnboardingModal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip"

interface LandingPageProps {
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  isAuthenticated: boolean;
  user: any;
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onPricingClick: () => void;
}

const LandingPage: React.FC<LandingPageProps> = ({
  description,
  setDescription,
  isAuthenticated,
  user,
  loginWithRedirect,
  logout,
  onSettingsClick,
  onPricingClick,
}) => {
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = useState(false);

  const handleSubmit = () => {
    if (description.trim()) {
      setShowOnboarding(true);
    }
  };

  const handleOnboardingComplete = async (sessionData: any) => {
    // Store session data in localStorage for after login
    localStorage.setItem('pendingCampaignData', JSON.stringify(sessionData));
    // Redirect to login
    loginWithRedirect();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="home-page">
      <Header 
        page="landing" 
        onBackClick={() => {}} 
        isAuthenticated={isAuthenticated}
        user={user}
        loginWithRedirect={loginWithRedirect}
        logout={logout}
        onSettingsClick={onSettingsClick}
        onPricingClick={onPricingClick}
      />
      <div className="content">
        <header className="App-header">
          <h1><span className="focus-text">Marketing made easy.</span></h1>
          <p className="App-description">So you can focus on what you do best.</p>
        </header>
        <div className="container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Describe your service or product"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button 
              className={`forward-button ${description ? 'active' : ''}`}
              onClick={handleSubmit}
              disabled={!description.trim()}
            >
              <SendIcon />
            </button>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button className="train-model-button">
                  <DiamondOutlined className="diamond" />
                  <span>Train model with your product image</span>
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Upload your product images to improve AI-generated content</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <OnboardingModal
        isOpen={showOnboarding}
        onClose={() => setShowOnboarding(false)}
        productDescription={description}
        onComplete={handleOnboardingComplete}
      />
    </div>
  );
};

export default LandingPage;
