import React from 'react';
import { Button } from "./ui/button"
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card"
import { Sparkles, Image, Users, ThumbsUp, Mail, BarChart } from 'lucide-react';

interface ExamplePrompt {
  label: string;
  text: string;
  icon: React.ReactNode;
}

interface ExamplePromptsCardProps {
  onSelectPrompt: (text: string) => void;
}

const examplePrompts: ExamplePrompt[] = [
  { label: "What's trending?", text: "What are some trending topics for my customers today or last week?", icon: <BarChart className="w-4 h-4 mr-2 text-blue-400" /> },
  { label: "Create image for ad", text: "Generate an image for an advertisement featuring my product", icon: <Image className="w-4 h-4 mr-2 text-blue-400" /> },
  { label: "Competitor analysis", text: "What are my competitors doing in the market?", icon: <Users className="w-4 h-4 mr-2 text-blue-400" /> },
  { label: "Customer perspective", text: "What would my customers think if...", icon: <ThumbsUp className="w-4 h-4 mr-2 text-blue-400" /> },
  { label: "Write newsletter", text: "Write a newsletter for my product launch", icon: <Mail className="w-4 h-4 mr-2 text-blue-400" /> },
  { label: "Create marketing plan", text: "Create a comprehensive marketing plan for my product. My budget is $1000.", icon: <Sparkles className="w-4 h-4 mr-2 text-blue-400" /> },
];

const ExamplePromptsCard: React.FC<ExamplePromptsCardProps> = ({ onSelectPrompt }) => {
  return (
    <Card className="w-full mb-4 bg-black text-white border-gray-700">
      <CardHeader>
        <CardTitle className="text-lg">
          <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Try these example prompts:
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-2">
          {examplePrompts.map((prompt, index) => (
            <Button
              key={index}
              variant="outline"
              className="text-[0.85rem] bg-black hover:bg-gray-900 text-slate-300 border-gray-700 flex items-center justify-start px-3 py-2 hover:text-white transition-colors"
              onClick={() => onSelectPrompt(prompt.text)}
            >
              {prompt.icon}
              {prompt.label}
            </Button>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ExamplePromptsCard;