import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog"
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { RefreshCw, CheckCircle, Loader2, ChevronLeft, PartyPopper } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { ScrollArea } from "./ui/scroll-area"
import { locationOptions } from '../utils/locationData';
import { COUNTRY_CODES } from '../utils/constants';

interface LocationOption {
  value: string;
  label: string;
}

interface OnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  productDescription: string;
  onComplete: (sessionData: any) => void;
  isLoggedIn?: boolean;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://adout.ai';

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  timeout: 15000,
  withCredentials: true,
});

const retryWithExponentialBackoff = async (
  fn: () => Promise<any>,
  maxRetries = 3,
  baseDelay = 1000
) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      const delay = baseDelay * Math.pow(2, i);
      console.log(`Retrying in ${delay}ms...`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
};

const OnboardingModal: React.FC<OnboardingModalProps> = ({
  isOpen,
  onClose,
  productDescription,
  onComplete,
  isLoggedIn,
}) => {
  const [step, setStep] = useState(1);
  const [sessionId, setSessionId] = useState('');
  const [location, setLocation] = useState<string>('global');
  const [targetAudiences, setTargetAudiences] = useState<string[]>([]);
  const [selectedAudience, setSelectedAudience] = useState('');
  const [customAudienceInput, setCustomAudienceInput] = useState('');
  const [insights, setInsights] = useState<string[]>([]);
  const [selectedInsight, setSelectedInsight] = useState('');
  const [creativeConcepts, setCreativeConcepts] = useState<string[]>([]);
  const [selectedConcept, setSelectedConcept] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [finalData, setFinalData] = useState<any>(null);
  const [isLoadingAudiences, setIsLoadingAudiences] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  const [isLoadingConcepts, setIsLoadingConcepts] = useState(false);

  useEffect(() => {
    if (isOpen && step === 1 && targetAudiences.length === 0) {
      console.log('OnboardingModal opened, fetching target audiences');
      fetchTargetAudiences();
    }
  }, [isOpen, step]);

  const fetchTargetAudiences = async () => {
    setIsLoadingAudiences(true);
    try {
      console.log('Fetching target audiences for product:', productDescription);
      const response = await retryWithExponentialBackoff(() => 
        axiosInstance.post('/onboarding/initialize', { productDescription })
      );
      console.log('Received target audiences:', response.data);
      setSessionId(response.data.sessionId);
      setTargetAudiences(response.data.targetAudiences);
    } catch (error) {
      console.error('Error fetching target audiences:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
      }
    } finally {
      setIsLoadingAudiences(false);
    }
  };

  const fetchInsights = async () => {
    try {
      const fullLocationName = location === 'global' ? 'global' : 
        (COUNTRY_CODES[location.toLowerCase()] || location);
      
      console.log('Fetching insights for audience:', selectedAudience);
      const response = await retryWithExponentialBackoff(() => 
        axiosInstance.post('/onboarding/insights', { 
          sessionId, 
          selectedAudience, 
          location: fullLocationName
        })
      );
      console.log('Received insights:', response.data);
      setInsights(response.data.insights);
    } catch (error) {
      console.error('Error fetching insights:', error);
      throw error;
    }
  };

  const fetchCreativeConcepts = async () => {
    try {
      console.log('Fetching creative concepts for insight:', selectedInsight);
      const response = await retryWithExponentialBackoff(() => 
        axiosInstance.post('/onboarding/creative-concepts', { sessionId, selectedInsight })
      );
      console.log('Received creative concepts:', response.data);
      setCreativeConcepts(response.data.creativeConcepts);
    } catch (error) {
      console.error('Error fetching creative concepts:', error);
      throw error;
    }
  };

  const finalizeOnboarding = async () => {
    try {
      console.log('Finalizing onboarding with concept:', selectedConcept);
      const response = await retryWithExponentialBackoff(() => 
        axiosInstance.post('/onboarding/finalize', { sessionId, selectedConcept })
      );
      console.log('Finalized onboarding response:', response.data);
      setCampaignName(response.data.campaignName);
      setFinalData(response.data.finalData);
    } catch (error) {
      console.error('Error finalizing onboarding:', error);
      throw error;
    }
  };

  const handleNext = async () => {
    console.log('Moving to next step, current step:', step);
    setIsLoadingNext(true);
    try {
      if (step === 1 && (selectedAudience || customAudienceInput) && location) {
        await fetchInsights();
        setStep(2);
      } else if (step === 2 && selectedInsight) {
        await fetchCreativeConcepts();
        setStep(3);
      } else if (step === 3 && selectedConcept) {
        console.log('Selected concept before finalizing:', selectedConcept);
        await finalizeOnboarding();
        setStep(4);
      }
    } catch (error) {
      console.error('Error in handleNext:', error);
      // Handle the error, maybe show an error message to the user
    } finally {
      setIsLoadingNext(false);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleComplete = () => {
    console.log('Onboarding completed, sending data:', finalData);
    if (finalData && finalData.userDataKey) {
      localStorage.setItem('userDataKey', finalData.userDataKey);
    }
    onComplete(finalData);
  };

  const handleFetchTargetAudiences = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoadingRefresh(true);
    try {
      await fetchTargetAudiences();
    } finally {
      setIsLoadingRefresh(false);
    }
  };

  const handleFetchInsights = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoadingInsights(true);
    try {
      await fetchInsights();
    } finally {
      setIsLoadingInsights(false);
    }
  };

  const handleFetchCreativeConcepts = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoadingConcepts(true);
    try {
      await fetchCreativeConcepts();
    } finally {
      setIsLoadingConcepts(false);
    }
  };

  const isNextButtonDisabled = () => {
    switch (step) {
      case 1:
        return selectedAudience === 'custom' ? !customAudienceInput.trim() : !selectedAudience;
      case 2:
        return !selectedInsight;
      case 3:
        return !selectedConcept;
      default:
        return false;
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <DialogTitle className="text-2xl font-bold mb-4 text-primary text-center">Understanding Your Customers</DialogTitle>
            <p className="mb-4 text-sm text-muted-foreground text-center">To create the best campaign for your product, let's identify who your customers are. We've provided some options, but feel free to add your own if you have a specific group in mind.</p>
            {isLoadingAudiences ? (
              <div className="flex justify-center items-center h-32">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
              </div>
            ) : (
              <div className="space-y-2 mb-4">
                {targetAudiences.map((audience, index) => (
                  <div key={index} className="flex items-center space-x-2 p-2 hover:bg-accent rounded">
                    <input
                      type="radio"
                      id={`audience-${index}`}
                      value={audience}
                      checked={selectedAudience === audience}
                      onChange={() => {
                        setSelectedAudience(audience);
                        setCustomAudienceInput('');
                      }}
                      className="hidden"
                    />
                    <label htmlFor={`audience-${index}`} className="flex items-center cursor-pointer w-full">
                      <div className="flex-shrink-0 w-5 h-5 mr-2">
                        {selectedAudience === audience ? (
                          <CheckCircle className="w-full h-full text-blue-500" />
                        ) : (
                          <div className="w-full h-full border-2 border-muted rounded-full"></div>
                        )}
                      </div>
                      <span className="text-sm flex-grow text-left">{audience}</span>
                    </label>
                  </div>
                ))}
                <div className="flex items-center space-x-2 p-2 hover:bg-accent rounded">
                  <input
                    type="radio"
                    id="audience-custom"
                    value="custom"
                    checked={selectedAudience === 'custom'}
                    onChange={() => {
                      setSelectedAudience('custom');
                      setTimeout(() => document.getElementById('custom-audience-input')?.focus(), 0);
                    }}
                    className="hidden"
                  />
                  <label htmlFor="audience-custom" className="flex items-center cursor-pointer w-full">
                    <div className="flex-shrink-0 w-5 h-5 mr-2">
                      {selectedAudience === 'custom' ? (
                        <CheckCircle className="w-full h-full text-blue-500" />
                      ) : (
                        <div className="w-full h-full border-2 border-muted rounded-full"></div>
                      )}
                    </div>
                    <span className="text-sm flex-grow text-left">Custom</span>
                  </label>
                </div>
              </div>
            )}
            {selectedAudience === 'custom' && (
              <Input
                id="custom-audience-input"
                placeholder="Describe your customers"
                value={customAudienceInput}
                onChange={(e) => setCustomAudienceInput(e.target.value)}
                className="mb-4 h-8"
                autoFocus
              />
            )}
            <div className="flex items-center justify-start space-x-4 mb-16">
              <span className="text-sm text-muted-foreground">Where do they live?</span>
              <Select value={location} onValueChange={setLocation}>
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="Select your location" />
                </SelectTrigger>
                <SelectContent>
                  <ScrollArea className="h-[150px]">
                    {locationOptions.map((option: LocationOption) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </ScrollArea>
                </SelectContent>
              </Select>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <DialogTitle className="text-2xl font-bold mb-4 text-primary text-center">Choose Your Customers' Main Motivation</DialogTitle>
            <p className="mb-4 text-sm text-muted-foreground text-center">Select the statement that best captures the primary drive or challenge your customers face. This will help us provide tailored creative concept to support your business effectively.</p>
            {isLoadingInsights ? (
              <div className="flex justify-center items-center h-32">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
              </div>
            ) : (
              <div className="space-y-2 mb-4">
                {insights.map((insight, index) => (
                  <div key={index} className="flex items-center space-x-2 p-2 hover:bg-accent rounded">
                    <input
                      type="radio"
                      id={`insight-${index}`}
                      value={insight}
                      checked={selectedInsight === insight}
                      onChange={() => setSelectedInsight(insight)}
                      className="hidden"
                    />
                    <label htmlFor={`insight-${index}`} className="flex items-center cursor-pointer w-full">
                      <div className="flex-shrink-0 w-5 h-5 mr-2">
                        {selectedInsight === insight ? (
                          <CheckCircle className="w-full h-full text-blue-500" />
                        ) : (
                          <div className="w-full h-full border-2 border-muted rounded-full"></div>
                        )}
                      </div>
                      <span className="text-sm flex-grow text-left">{insight}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </>
        );
      case 3:
        return (
          <>
            <DialogTitle className="text-2xl font-bold mb-4 text-primary text-center">Choose Your Creative Idea</DialogTitle>
            <p className="mb-4 text-sm text-muted-foreground text-center">Select the creative concept that best aligns with your product and customer needs.</p>
            {isLoadingConcepts ? (
              <div className="flex justify-center items-center h-32">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
              </div>
            ) : (
              <div className="space-y-2 mb-4">
                {creativeConcepts.map((concept, index) => (
                  <div key={index} className="flex items-center space-x-2 p-2 hover:bg-accent rounded">
                    <input
                      type="radio"
                      id={`concept-${index}`}
                      value={concept}
                      checked={selectedConcept === concept}
                      onChange={() => setSelectedConcept(concept)}
                      className="hidden"
                    />
                    <label htmlFor={`concept-${index}`} className="flex items-center cursor-pointer w-full">
                      <div className="flex-shrink-0 w-5 h-5 mr-2">
                        {selectedConcept === concept ? (
                          <CheckCircle className="w-full h-full text-blue-500" />
                        ) : (
                          <div className="w-full h-full border-2 border-muted rounded-full"></div>
                        )}
                      </div>
                      <span className="text-sm flex-grow text-left">{concept}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </>
        );
      case 4:
        return (
          <>
            <DialogTitle className="text-3xl font-bold mb-8 text-center text-primary">Congratulations!</DialogTitle>
            <div className="text-center mb-16">
              <div className="w-20 h-20 mx-auto mb-8">
                <PartyPopper className="w-full h-full text-blue-500" />
              </div>
              <p className="text-xl mb-8 text-muted-foreground text-center">Your campaign is ready to launch!</p>
              <h3 className="text-2xl font-semibold mb-4 text-primary text-center"> </h3>
              <p className="text-3xl font-bold text-primary text-center">{campaignName}</p>
            </div>
            <p className="text-center mb-8 text-sm text-muted-foreground">
              You're all set to begin your marketing journey. On the next screen, you'll log in and get started with your new campaign.
            </p>
          </>
        );
      default:
        return null;
    }
  };

  const renderProgressDots = () => {
    return (
      <div className="flex justify-center space-x-2">
        {[1, 2, 3, 4].map((dot) => (
          <div
            key={dot}
            className={`w-2 h-2 rounded-full ${
              step === dot ? 'bg-white' : 'bg-gray-500'
            }`}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => {}} modal={true}>
      <DialogContent className="sm:max-w-[80vmin] sm:h-[80vmin] sm:w-[80vmin] flex flex-col bg-black text-white">
        <DialogHeader className="relative">
          {step > 1 && (
            <ChevronLeft
              className="h-4 w-4 absolute left-2 top-2 cursor-pointer text-gray-400 hover:text-white"
              onClick={handleBack}
            />
          )}
        </DialogHeader>
        <div className="flex-grow overflow-y-auto px-4 flex flex-col">
          {renderStepContent()}
        </div>
        <div className="mt-auto pt-4 flex flex-col items-center">
          <div className="flex items-center space-x-4 mb-4">
            {step === 1 && (
              <Button variant="outline" size="icon" onClick={handleFetchTargetAudiences} disabled={isLoadingRefresh}>
                {isLoadingRefresh ? <Loader2 className="h-4 w-4 animate-spin" /> : <RefreshCw className="h-4 w-4" />}
              </Button>
            )}
            {step === 2 && (
              <Button variant="outline" size="icon" onClick={handleFetchInsights} disabled={isLoadingInsights}>
                {isLoadingInsights ? <Loader2 className="h-4 w-4 animate-spin" /> : <RefreshCw className="h-4 w-4" />}
              </Button>
            )}
            {step === 3 && (
              <Button variant="outline" size="icon" onClick={handleFetchCreativeConcepts} disabled={isLoadingConcepts}>
                {isLoadingConcepts ? <Loader2 className="h-4 w-4 animate-spin" /> : <RefreshCw className="h-4 w-4" />}
              </Button>
            )}
            {step < 4 ? (
              <Button onClick={handleNext} disabled={isLoadingNext || isNextButtonDisabled()}>
                {isLoadingNext ? <Loader2 className="h-4 w-4 animate-spin mr-2" /> : null}
                Next
              </Button>
            ) : (
              <Button onClick={handleComplete}>
                {isLoggedIn ? 'Get Started' : 'Continue to Login'}
              </Button>
            )}
          </div>
          <p className="text-xs text-muted-foreground text-center mb-2">You can always change these settings later.</p>
          {renderProgressDots()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingModal;