import React from 'react';
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { DiamondOutlined } from '@mui/icons-material';

interface PlanCardProps {
  userPlan: 'free' | 'basic' | 'pro';
  onPricingClick: () => void;
}

const PlanCard: React.FC<PlanCardProps> = ({ userPlan, onPricingClick }) => {
  const getPlanDescription = (plan: string) => {
    switch (plan) {
      case 'free':
        return 'Access basic features with our free plan';
      case 'basic':
        return 'Enhanced features for growing businesses';
      case 'pro':
        return 'Full access to all premium features';
      default:
        return '';
    }
  };

  return (
    <Card className="p-6 bg-black border border-[#333]">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-white">Current Plan</h3>
          <span className="px-3 py-1 rounded-full text-sm bg-gray-800 text-white">
            {userPlan.toUpperCase()}
          </span>
        </div>
        <div className="text-sm text-gray-400">
          {getPlanDescription(userPlan)}
        </div>
        <div className="flex gap-4">
          <div className="flex-1"></div>
          <Button 
            onClick={onPricingClick}
            className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white px-4 py-1 rounded-full transition-all transform hover:scale-105"
          >
            <DiamondOutlined className="mr-2" />
            {userPlan === 'free' ? 'Upgrade Plan' : 'Change Plan'}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default PlanCard; 