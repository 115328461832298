import React, { useState, useEffect, useMemo } from 'react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card";
import { useBrand } from '../hooks/useBrand';
import { useProduct } from '../hooks/useProduct';
import { useCampaign, Campaign } from '../hooks/useCampaign';
import { Link } from 'react-router-dom';

interface CampaignInfoProps {
  campaignInfo: string;
  latestCampaign: Campaign | null;
  currentBrand: any;
  setChatMessages: React.Dispatch<React.SetStateAction<string[]>>;
  onSettingsClick: () => void;
}

interface EditedData extends Partial<Campaign> {
  brandName: string;
  productName: string;
}

const CampaignInfo: React.FC<CampaignInfoProps> = ({
  campaignInfo,
  latestCampaign,
  currentBrand,
  setChatMessages,
  onSettingsClick,
}) => {
  const [editedData, setEditedData] = useState<EditedData | null>(null);

  const { selectedBrand } = useBrand(currentBrand);
  const { selectedProduct } = useProduct(selectedBrand?.id);
  const { updateCampaign, createCampaign } = useCampaign(selectedBrand?.id);

  const parseCampaignInfo = useMemo(() => {
    try {
      const cleanedInfo = campaignInfo.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
      const lines = cleanedInfo.split('\n');
      const data: { [key: string]: string } = {};
      lines.forEach((line: string) => {
        const [key, ...valueParts] = line.split(':');
        const value = valueParts.join(':').trim();
        if (key && value) {
          data[key.trim().replace('- ', '')] = value;
        }
      });
      return data;
    } catch (error) {
      console.error('Error parsing campaign info:', error);
      return {};
    }
  }, [campaignInfo]);

  useEffect(() => {
    if (latestCampaign) {
      const newEditedData: EditedData = {
        name: latestCampaign.name, // Add this line
        brandName: selectedBrand?.name || '',
        productName: selectedProduct?.name || '',
        target_audience: latestCampaign.target_audience,
        insight: latestCampaign.insight,
        creative_concept: latestCampaign.creative_concept,
      };
      setEditedData(newEditedData);
    } else {
      const newEditedData: EditedData = {
        name: parseCampaignInfo['Campaign name'] || '', // Add this line
        brandName: selectedBrand?.name || parseCampaignInfo['Brand name'] || '',
        productName: selectedProduct?.name || parseCampaignInfo['Associated product'] || '',
        target_audience: parseCampaignInfo['Target audience'] || '',
        insight: parseCampaignInfo['Campaign insight'] || '',
        creative_concept: parseCampaignInfo['Creative concept'] || '',
      };
      setEditedData(newEditedData);
    }
  }, [latestCampaign, selectedBrand, selectedProduct, parseCampaignInfo]);

  if (!editedData) {
    return <div>Loading campaign information...</div>;
  }

  return (
    <div className="campaign-info-container text-xs text-center pb-6">
      <HoverCard>
        <HoverCardTrigger asChild>
          <div className="campaign-info-summary cursor-pointer text-gray-400">
            {editedData.name} campaign was used to process your request.{' '}
            <Link to="/settings" className="text-blue-500 hover:underline" onClick={onSettingsClick}>
              Settings
            </Link>
          </div>
        </HoverCardTrigger>
        <HoverCardContent className="w-80 bg-black text-white p-4 rounded-md shadow-lg">
          <div className="campaign-info-details space-y-2 text-left">
            {Object.entries(editedData).map(([key, value]) => (
              key !== 'name' && (
                <div key={key} className="campaign-info-item">
                  <strong className="capitalize">{key.replace('_', ' ')}:</strong> {value}
                </div>
              )
            ))}
          </div>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};

export default CampaignInfo;