import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ChatOptionsModal from './ChatOptionsModal';
import OnboardingModal from './OnboardingModal';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import ImageGallery from './ImageGallery';

// Add interface for chat data
interface Chat {
  id: string;
  last_accessed: string;
  created_at: string;
}

const MainPage: React.FC = () => {
  const [showOptionsModal, setShowOptionsModal] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showProductInput, setShowProductInput] = useState(false);
  const [hasExistingChats, setHasExistingChats] = useState(false);
  const [productDescription, setProductDescription] = useState('');
  const navigate = useNavigate();
  const { user, getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoading(false);
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      checkExistingChats();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/images`);
        console.log('Gallery images response:', response.data);
        if (response.data.images) {
          console.log('Setting gallery images:', response.data.images);
          setGalleryImages(response.data.images);
        }
      } catch (error) {
        console.error('Error fetching gallery images:', error);
      }
    };

    fetchImages();
  }, []);

  const checkExistingChats = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chats`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHasExistingChats(response.data.length > 0);
    } catch (error) {
      console.error('Error checking existing chats:', error);
    }
  };

  const handleContinue = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get<Chat[]>(`${process.env.REACT_APP_API_URL}/api/chats`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.length > 0) {
        console.log('All chats:', response.data.map(chat => ({
          id: chat.id,
          last_accessed: chat.last_accessed,
          created_at: chat.created_at
        })));

        const mostRecentChat = response.data.sort((a: Chat, b: Chat) => {
          const dateA = a.last_accessed ? new Date(a.last_accessed) : new Date(a.created_at);
          const dateB = b.last_accessed ? new Date(b.last_accessed) : new Date(b.created_at);
          
          console.log(`Comparing chats:
            A: ${a.id} (${dateA.toISOString()})
            B: ${b.id} (${dateB.toISOString()})
          `);
          
          return dateB.getTime() - dateA.getTime();
        })[0];

        console.log('Selected most recent chat:', mostRecentChat);
        navigate(`/chat/${mostRecentChat.id}`);
      }
    } catch (error) {
      console.error('Error continuing chat:', error);
    }
  };

  const handleNewCampaign = () => {
    setShowOptionsModal(false);
    setShowProductInput(true);
  };

  const handleProductSubmit = () => {
    if (productDescription.trim()) {
      setShowProductInput(false);
      setShowOnboarding(true);
    }
  };

  const handleOnboardingComplete = async (sessionData: any) => {
    try {
      const token = await getAccessTokenSilently();
      
      console.log('Original session data:', sessionData);

      const formattedData = {
        campaignData: {
          campaignName: sessionData.campaignName || `${sessionData.productName} Campaign`,
          target_audience: sessionData.target_audience,
          insight: sessionData.insight,
          creative_concept: sessionData.creative_concept,
          location: sessionData.location || 'global'
        },
        productDescription: sessionData.productName
      };

      console.log('Sending formatted data:', formattedData);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/onboarding/complete`,
        formattedData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      console.log('Onboarding response:', response.data);

      if (response.data.chatId) {
        localStorage.setItem('currentChatId', response.data.chatId);
        navigate(`/chat/${response.data.chatId}`, {
          state: { 
            initialChatId: response.data.chatId,
            skipFetch: true
          }
        });
      } else {
        console.error('No chatId received from onboarding completion');
      }
    } catch (error) {
      console.error('Error completing onboarding:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
      }
    }
  };

  const handleLogin = () => {
    console.log('Handling login click');  // Add debug log
    loginWithRedirect();  // This should redirect to Auth0 login page
  };

  const handlePricingClick = () => {
    navigate('/pricing');  // Add this handler
  };

  return (
    <div className="min-h-screen bg-transparent text-white flex flex-col">
      <div className="flex-1 flex flex-col items-center justify-center p-4 bg-transparent">
        {isInitialLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <ImageGallery images={galleryImages} />
            <ChatOptionsModal
              isOpen={showOptionsModal}
              onClose={() => setShowOptionsModal(false)}
              onContinue={handleContinue}
              onNewCampaign={handleNewCampaign}
              showContinueOption={hasExistingChats}
              userName={user?.name}
              isAuthenticated={isAuthenticated}
              onLogin={handleLogin}
            />

            <Dialog 
              open={showProductInput} 
              onOpenChange={() => {}} 
              modal={true}
            >
              <DialogContent className="sm:max-w-[425px] bg-black text-white">
                <DialogHeader>
                  <DialogTitle className="text-2xl font-bold text-center mb-4">
                    Describe Your Product or Service
                  </DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                  <Input
                    value={productDescription}
                    onChange={(e) => setProductDescription(e.target.value)}
                    placeholder="Enter a brief description"
                    className="bg-white text-black"
                  />
                  <Button
                    onClick={handleProductSubmit}
                    disabled={!productDescription.trim()}
                    variant="outline"
                    className="w-full py-6 text-lg bg-white hover:bg-gray-200 text-black hover:text-black transition-colors"
                  >
                    Continue
                  </Button>
                </div>
              </DialogContent>
            </Dialog>

            <OnboardingModal
              isOpen={showOnboarding}
              onClose={() => {
                setShowOnboarding(false);
                setShowOptionsModal(true);
              }}
              productDescription={productDescription}
              onComplete={handleOnboardingComplete}
              isLoggedIn={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MainPage;
