import { useState, useEffect, useCallback, useRef } from 'react';
import { UseChatWebSocketProps, UseChatWebSocketReturn, WebSocketMessage } from '../types';

const PING_INTERVAL = 30000;
const RECONNECT_DELAY = 2000;
const MAX_RECONNECT_ATTEMPTS = 5;
const CONNECTION_THROTTLE = 5000;

interface StreamState {
    type: 'normal' | 'market_analysis';
    content: string;
}

export const useChatWebSocket = ({ 
    activeChatId,
    onMessageReceived,
    setIsGeneratingImage,
    setGeneratedImageUrl,
    setImageKey,
    setIsUsingToolState,
    setCurrentToolState,
    setFollowUpPrompts,
    addMessage,
    requestToolSummary
}: UseChatWebSocketProps): UseChatWebSocketReturn => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamingContent, setStreamingContent] = useState('');
    const [isUsingTool, setIsUsingTool] = useState(false);
    const [currentTool, setCurrentTool] = useState<string | null>(null);
    
    // Add all refs
    const socketRef = useRef<WebSocket | null>(null);
    const currentStreamRef = useRef<StreamState>({ type: 'normal', content: '' });
    const messageProcessedRef = useRef(new Set<string>());
    const lastProcessedChunkRef = useRef<string>('');
    const mountedRef = useRef(true);
    const toolSummaryRequestedRef = useRef(false);
    const pingIntervalRef = useRef<NodeJS.Timeout>();
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
    const reconnectAttemptsRef = useRef<number>(0);
    const isConnectingRef = useRef<boolean>(false);
    const lastConnectionAttemptRef = useRef<number>(0);
    const lastToolUpdateRef = useRef<string>('');

    const handleWebSocketMessage = useCallback((data: WebSocketMessage) => {
        if (!data || !data.type || !mountedRef.current) return;

        // Create unique message ID based on content and timestamp
        const messageId = `${data.type}-${data.chatId}-${Date.now()}-${data.content || ''}`;
        
        // Skip if we've already processed this exact message
        if (messageProcessedRef.current.has(messageId)) return;
        messageProcessedRef.current.add(messageId);

        switch (data.type) {
            case 'messageStart':
                if (data.chatId === activeChatId) {
                    setIsStreaming(true);
                    setStreamingContent('');
                    currentStreamRef.current = { type: 'normal', content: '' };
                }
                break;

            case 'messageChunk':
                if (data.chatId === activeChatId && data.content) {
                    console.log('Received message chunk:', data.content);
                    // Append new content to existing content
                    setStreamingContent(prev => prev + data.content);
                }
                break;

            case 'messageComplete':
                if (data.chatId === activeChatId) {
                    setIsStreaming(false);
                    // Final message will be added by the chat component
                }
                break;

            case 'followUpPrompts':
                if (Array.isArray(data.prompts) && data.prompts.length > 0) {
                    setFollowUpPrompts(data.prompts);
                }
                break;

            case 'toolUseUpdate':
                if (data.chatId === activeChatId) {
                    // Create unique identifier for this tool update
                    const updateId = `${data.chatId}-${data.tool}-${Date.now()}`;
                    
                    // Only process if it's not a duplicate within last 1000ms
                    if (updateId !== lastToolUpdateRef.current) {
                        console.log('Tool use update received:', data);
                        lastToolUpdateRef.current = updateId;
                        setIsUsingTool(true);
                        setIsUsingToolState(true);
                        setCurrentTool(data.tool || null);
                        setCurrentToolState(data.tool || null);
                        if (data.tool === 'generate_new_image') {
                            setIsGeneratingImage(true);
                        }
                        if (data.tool === 'market_analysis') {
                            currentStreamRef.current = { type: 'market_analysis', content: '' };
                            setStreamingContent('');
                        }
                    }
                }
                break;

            case 'toolComplete':
                if (data.chatId === activeChatId) {
                    toolSummaryRequestedRef.current = false;
                    setIsUsingTool(false);
                    setIsUsingToolState(false);
                    setCurrentTool(null);
                    setCurrentToolState(null);
                    currentStreamRef.current = { type: 'normal', content: '' };
                }
                break;

            case 'newImageGenerated':
                if (data.chatId === activeChatId) {
                    setGeneratedImageUrl(data.imageUrl || null);
                    setImageKey(data.imageKey || null);
                    setIsGeneratingImage(false);
                    setIsUsingToolState(false);
                    setCurrentToolState(null);
                    addMessage(`Generated image: ${data.imageUrl}`);
                    if (!toolSummaryRequestedRef.current) {
                        toolSummaryRequestedRef.current = true;
                        requestToolSummary(activeChatId);
                    }
                }
                break;
        }

        onMessageReceived(data);
    }, [activeChatId, addMessage, onMessageReceived]);

    const cleanup = useCallback(() => {
        if (pingIntervalRef.current) {
            clearInterval(pingIntervalRef.current);
            pingIntervalRef.current = undefined;
        }
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
            reconnectTimeoutRef.current = undefined;
        }
        if (socketRef.current) {
            if (socketRef.current.readyState === WebSocket.OPEN) {
                socketRef.current.close(1000, "Normal closure");
            }
            socketRef.current = null;
        }
        isConnectingRef.current = false;
        setSocket(null);
    }, []);

    const connectWebSocket = useCallback(() => {
        // Don't connect if we don't have a chatId
        if (!activeChatId) {
            return;
        }

        // Check if we already have a working connection
        if (socketRef.current?.readyState === WebSocket.OPEN) {
            return;
        }

        // Check if we're already trying to connect
        if (isConnectingRef.current) {
            return;
        }

        // Throttle connection attempts
        const now = Date.now();
        if (now - lastConnectionAttemptRef.current < CONNECTION_THROTTLE) {
            return;
        }

        // Clean up any existing connection first
        cleanup();

        // Update connection attempt timestamp
        lastConnectionAttemptRef.current = now;
        isConnectingRef.current = true;

        try {
            console.log('Creating new WebSocket connection...');
            const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws`);
            socketRef.current = ws;

            ws.onopen = () => {
                if (!mountedRef.current) {
                    ws.close(1000, "Component unmounted");
                    return;
                }

                console.log('WebSocket connection established');
                setSocket(ws);
                reconnectAttemptsRef.current = 0;
                isConnectingRef.current = false;

                // Start ping interval
                if (pingIntervalRef.current) {
                    clearInterval(pingIntervalRef.current);
                }

                // Send initial message with chatId
                try {
                    ws.send(JSON.stringify({ 
                        type: 'init', 
                        chatId: activeChatId 
                    }));

                    // Set up ping interval
                    pingIntervalRef.current = setInterval(() => {
                        if (ws.readyState === WebSocket.OPEN) {
                            try {
                                ws.send(JSON.stringify({ type: 'ping' }));
                            } catch (error) {
                                console.error('Error sending ping:', error);
                                cleanup();
                                connectWebSocket(); // Try to reconnect
                            }
                        }
                    }, PING_INTERVAL);
                } catch (error) {
                    console.error('Error in WebSocket initialization:', error);
                    cleanup();
                }
            };

            ws.onmessage = (event) => {
                if (!mountedRef.current) return;
                try {
                    const data = JSON.parse(event.data);
                    if (!data || !data.type || data.type === 'pong') return;
                    handleWebSocketMessage(data);
                } catch (error) {
                    console.error('Error processing WebSocket message:', error);
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                cleanup();
                // Don't reconnect immediately on error
                setTimeout(() => {
                    if (mountedRef.current) {
                        connectWebSocket();
                    }
                }, RECONNECT_DELAY);
            };

            ws.onclose = (event) => {
                console.log('WebSocket closed:', event.code, event.reason);
                cleanup();

                if (!mountedRef.current || event.code === 1000) {
                    return; // Normal closure or component unmounted
                }

                if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
                    reconnectAttemptsRef.current++;
                    const delay = RECONNECT_DELAY * Math.pow(2, reconnectAttemptsRef.current - 1);
                    console.log(`Attempting to reconnect (${reconnectAttemptsRef.current}/${MAX_RECONNECT_ATTEMPTS}) in ${delay}ms`);
                    
                    if (reconnectTimeoutRef.current) {
                        clearTimeout(reconnectTimeoutRef.current);
                    }
                    
                    reconnectTimeoutRef.current = setTimeout(() => {
                        if (mountedRef.current) {
                            connectWebSocket();
                        }
                    }, delay);
                }
            };
        } catch (error) {
            console.error('Error creating WebSocket:', error);
            cleanup();
        }
    }, [activeChatId, cleanup, handleWebSocketMessage]);

    // Connect only when activeChatId changes
    useEffect(() => {
        if (activeChatId) {
            connectWebSocket();
        }
        return () => cleanup();
    }, [activeChatId, connectWebSocket, cleanup]);

    // Cleanup on unmount
    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
            cleanup();
        };
    }, [cleanup]);

    // Add cleanup of messageProcessedRef when activeChatId changes
    useEffect(() => {
        messageProcessedRef.current.clear();
        currentStreamRef.current = { type: 'normal', content: '' };
        setStreamingContent('');
        setIsStreaming(false);
        setIsUsingTool(false);
        setCurrentTool(null);
    }, [activeChatId]);

    return {
        socket,
        isStreaming,
        streamingContent,
        setStreamingContent,
        setIsStreaming
    };
};