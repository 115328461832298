import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

interface StreamingLinesProps {
    content: string;
    isStreaming: boolean;
}

const StreamingLines: React.FC<StreamingLinesProps> = ({ content, isStreaming }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [displayContent, setDisplayContent] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    // Update display content and scroll as new content arrives
    useEffect(() => {
        if (content) {
            setDisplayContent(content);
            if (containerRef.current) {
                containerRef.current.scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'end'
                });
            }
        }
    }, [content]);

    // Handle streaming state changes
    useEffect(() => {
        if (isStreaming) {
            setIsVisible(true);
        } else {
            // When streaming stops, wait a bit before hiding
            const timer = setTimeout(() => {
                setIsVisible(false);
                setDisplayContent('');
            }, 100); // Short delay for smooth transition
            return () => clearTimeout(timer);
        }
    }, [isStreaming]);

    // Don't render if not visible
    if (!isVisible) return null;

    return (
        <div 
            ref={containerRef}
            className="chat-message ai-message bg-gray-900 bg-opacity-70 p-3 rounded-lg mb-1 mt-1 transition-all duration-300 ease-in-out"
            style={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? 'translateY(0)' : 'translateY(10px)',
                transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out'
            }}
        >
            {/* Show content as it streams in */}
            <div className="prose prose-invert max-w-none text-[0.85rem]">
                <ReactMarkdown>
                    {displayContent || ''}
                </ReactMarkdown>
            </div>
            
            {/* Show loading indicator only while actively streaming */}
            {/* {isStreaming && (
                <div 
                    className="h-6 bg-gray-800 rounded animate-pulse mt-2" 
                    style={{ width: `${Math.random() * 40 + 60}%` }}
                />
            )} */}
        </div>
    );
};

export default StreamingLines;