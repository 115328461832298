import React, { useState, useEffect } from 'react';
import '../styles/LoadingAnimations.css';

const loadingMessages = [
  "Loading...",
  "Generating image...",
  "Crafting visuals...",
  "Bringing ideas to life...",
  "Painting with pixels...",
  "Unleashing creativity...",
  "Designing your vision...",
  "Imagining possibilities...",
  "Sculpting digital art...",
];

const CreativeLoadingAnimation: React.FC = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="creative-loading-container">
      <div className="creative-loading-animation">
        {[...Array(2)].map((_, i) => (
          <div key={i} className="loading-square" style={{ '--i': i } as React.CSSProperties}></div>
        ))}
      </div>
      <div className="loading-text">{loadingMessages[messageIndex]}</div>
    </div>
  );
};

export default CreativeLoadingAnimation;