import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog"
import { Button } from "./ui/button"

interface ChatOptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  onNewCampaign: () => void;
  showContinueOption: boolean;
  userName?: string;
  isAuthenticated: boolean;
  onLogin: () => void;
}

const ChatOptionsModal: React.FC<ChatOptionsModalProps> = ({
  isOpen,
  onClose,
  onContinue,
  onNewCampaign,
  showContinueOption,
  userName,
  isAuthenticated,
  onLogin,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => {}} modal={true}>
      <DialogContent 
        className="sm:max-w-[425px] border border-gray-600 fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        style={{ 
          background: 'linear-gradient(to bottom right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.95))',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)'
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-3xl font-bold text-center mb-2 text-white">
            {isAuthenticated 
              ? "Welcome back to Adout"
              : "Welcome to Adout"
            }
          </DialogTitle>
          {isAuthenticated && userName && (
            <p className="text-xl text-center text-gray-300 mb-8">
              {userName}
            </p>
          )}
          {!isAuthenticated && (
            <p className="text-xl text-center text-gray-300 mb-8">
              Please login to continue
            </p>
          )}
        </DialogHeader>
        <div className="flex flex-col gap-4">
          {isAuthenticated ? (
            <>
              {showContinueOption && (
                <Button
                  onClick={onContinue}
                  variant="outline"
                  className="w-full py-6 text-lg bg-white/90 hover:bg-white text-black hover:text-black transition-colors"
                >
                  Continue where you left off
                </Button>
              )}
              <Button
                onClick={onNewCampaign}
                variant="outline"
                className="w-full py-6 text-lg bg-white/90 hover:bg-white text-black hover:text-black transition-colors"
              >
                Start a new campaign
              </Button>
            </>
          ) : (
            <Button
              onClick={onLogin}
              variant="outline"
              className="w-full py-6 text-lg bg-white/90 hover:bg-white text-black hover:text-black transition-colors"
            >
              Sign In
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChatOptionsModal;
