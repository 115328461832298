// src/hooks/useBrand.ts
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Brand {
  id: string;
  name: string;
  website?: string;
  logo_url?: string;
  style_image1_url?: string;
  style_image2_url?: string;
  style_image3_url?: string;
  style_image4_url?: string;
  is_trained: boolean;
  use_brand_style: boolean;
  use_logo: boolean;
}

interface User {
  id: string;
  // Add other user properties as needed
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function useBrand(user: User | null) {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [editedBrand, setEditedBrand] = useState<Brand | null>(null);

  const fetchBrands = useCallback(async () => {
    if (!user || !user.id) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/brands/user/${user.id}`);
      setBrands(response.data);
      if (response.data.length > 0 && !selectedBrand) {
        setSelectedBrand(response.data[0]);
      }
    } catch (err) {
      console.error('Error fetching brands:', err);
      setError('Failed to fetch brands');
    } finally {
      setLoading(false);
    }
  }, [user, selectedBrand]);

  const createBrand = useCallback(async (): Promise<Brand> => {
    if (!user) throw new Error('User is not defined');
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('/api/brands', { name: 'New Brand', userId: user.id });
      setBrands(prevBrands => [...prevBrands, response.data]);
      setSelectedBrand(response.data);
      return response.data;
    } catch (err) {
      console.error('Error creating brand:', err);
      setError('Failed to create brand');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [user]);

  const updateBrand = useCallback((updatedFields: Partial<Brand>) => {
    setEditedBrand(prev => {
      if (!prev) return prev;
      const updated = { ...prev, ...updatedFields };
      setIsDirty(JSON.stringify(updated) !== JSON.stringify(selectedBrand));
      return updated;
    });
  }, [selectedBrand]);

  const saveChanges = async () => {
    if (!isDirty || !editedBrand || !selectedBrand) return null;
    try {
      console.log('Sending update request with data:', editedBrand);
      const response = await axios.put(`/api/brands/${selectedBrand.id}`, editedBrand);
      console.log('Server response:', response.data);
      setSelectedBrand(response.data);
      setBrands(prevBrands => 
        prevBrands.map(b => b.id === response.data.id ? response.data : b)
      );
      setEditedBrand(response.data);
      setIsDirty(false);
      return response.data;
    } catch (error) {
      console.error('Error saving brand:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
      }
      throw error;
    }
  };

  useEffect(() => {
    if (selectedBrand && !editedBrand) {
      setEditedBrand(selectedBrand);
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (user && user.id) {
      fetchBrands();
    }
  }, [user, fetchBrands]);

  return { 
    brands, 
    selectedBrand, 
    editedBrand,
    loading, 
    error, 
    fetchBrands, 
    createBrand, 
    updateBrand, 
    saveChanges, 
    isDirty, 
    setSelectedBrand
  };
}