import axios from 'axios';
import { ChatMessage } from '../types';

export const dbService = {
    getChatMessages: async (chatId: string): Promise<string[]> => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/chats/${chatId}/messages`
        );
        
        // Format messages for display
        return response.data.messages.map((msg: ChatMessage) => {
            if (msg.message.startsWith('Generated image:')) {
                return msg.message;
            }
            // Preserve MARKET_ANALYSIS prefix if present
            if (msg.message.startsWith('MARKET_ANALYSIS:')) {
                return msg.message;
            }
            return msg.is_user ? `User: ${msg.message}` : `AI: ${msg.message}`;
        });
    }
}; 