import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import LandingPage from './components/LandingPage';
import MainPage from './components/MainPage';
import LoginPage from './components/LoginPage';
import ChatPage from './components/ChatPage';
import SettingsPage from './components/SettingsPage';
import { useAuth0 } from '@auth0/auth0-react';
import PricingPage from './components/PricingPage';
import { TooltipProvider } from './components/ui/tooltip';
import axios from 'axios';
import { Routes, Route, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import { Tool, FollowUpPrompt, ChatResponse } from './types';
import ErrorBoundary from './components/ErrorBoundary';
import { useWebSocketConnection } from './hooks/useWebSocketConnection';
import { toast } from './components/ui/use-toast';

type Page = 'home' | 'chat' | 'settings' | 'pricing';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const WS_URL = process.env.REACT_APP_WS_URL || 'ws://localhost:3001/ws';

if (!API_URL || !WS_URL) {
  console.error('API_URL or WS_URL is not defined in the environment variables');
}

interface AppRoutesProps {
  isAuthenticated: boolean;
  homePageProps: any;
  chatPageProps: any;
  settingsPageProps: any;
  pricingPageProps: any;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ isAuthenticated, homePageProps, chatPageProps, settingsPageProps, pricingPageProps }) => {
  const { chatId } = useParams<{ chatId?: string }>();
  const navigate = useNavigate();
  const initialChatId = chatId ? chatId.toString() : null;

  return (
    <Routes>
      <Route path="/" element={<MainPage {...homePageProps} />} />
      <Route path="/landing" element={
        isAuthenticated ? <Navigate to="/chat" replace /> : <LandingPage {...homePageProps} />
      } />
      <Route path="/chat" element={<ChatPage {...chatPageProps} />} />
      <Route path="/chat/:chatId" element={<ChatPage {...chatPageProps} initialChatId={initialChatId} />} />
      <Route path="/settings" element={
        isAuthenticated ? <SettingsPage {...settingsPageProps} /> : <Navigate to="/" replace />
      } />
      <Route path="/pricing" element={<PricingPage {...pricingPageProps} />} />
    </Routes>
  );
};

const App: React.FC = () => {
  const { isAuthenticated, isLoading, user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [description, setDescription] = useState('');
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [localUser, setLocalUser] = useState<any>(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [allowUnauthenticatedChat, setAllowUnauthenticatedChat] = useState(false);
  const [imageKey, setImageKey] = useState<string | null>(null);
  const [userDataKey, setUserDataKey] = useState<string | null>(null);
  const [followUpPrompts, setFollowUpPrompts] = useState<any[]>([]);
  const [isUsingTool, setIsUsingTool] = useState(false);
  const [currentTool, setCurrentTool] = useState<string | null>(null);
  const [currentChatId, setCurrentChatId] = useState<string | null>(() => {
    return localStorage.getItem('currentChatId');
  });
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [latestCampaign, setLatestCampaign] = useState<any>(null);
  const [currentBrand, setCurrentBrand] = useState<any>(null);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  useEffect(() => {
    if (currentChatId) {
      localStorage.setItem('currentChatId', currentChatId);
    }
  }, [currentChatId]);

  const location = useLocation();
  const navigate = useNavigate();

  const { ws, connect, disconnect } = useWebSocketConnection(WS_URL);

  const setIsGeneratingImageWithLog: React.Dispatch<React.SetStateAction<boolean>> = useCallback((value) => {
    console.log('setIsGeneratingImage called with value:', value);
    console.trace('setIsGeneratingImage call stack');
    setIsGeneratingImage(value);
  }, []);

  const handleWebSocketMessage = useCallback((event: MessageEvent) => {
    const data = JSON.parse(event.data);
    console.log('WebSocket message received:', data);
    switch (data.type) {
      case 'toolUseUpdate':
        console.log('Received toolUseUpdate message');
        setIsUsingTool(true);
        setCurrentTool(data.tool);
        if (data.tool === 'adjust_image' || data.tool === 'generate_new_image') {
          setIsGeneratingImageWithLog(true);
        }
        break;
      case 'imageGenerationStarted':
        console.log('Received imageGenerationStarted message');
        setIsGeneratingImageWithLog(true);
        break;
      case 'newImageGenerated':
        console.log('Received newImageGenerated message:', data);
        setGeneratedImageUrl(data.imageUrl);
        setImageKey(data.imageKey);
        setIsGeneratingImageWithLog(false);
        setIsUsingTool(false);
        setCurrentTool(null);
        break;
      case 'imageUrlUpdated':
        console.log('Received imageUrlUpdated message');
        // Only update if the original URL matches the current URL
        if (data.originalImageUrl === generatedImageUrl) {
          setGeneratedImageUrl(data.imageUrl);
          // Update chat messages with the new Azure image URL
          setChatMessages(prevMessages => {
            const updatedMessages = prevMessages.map(msg => 
              msg.startsWith('Image Generated:') ? `Image Generated: ${data.imageUrl}` : msg
            );
            return updatedMessages;
          });
        }
        break;
      default:
        console.log('Unknown WebSocket message type:', data.type);
    }
  }, [generatedImageUrl, setGeneratedImageUrl, setImageKey, setIsGeneratingImageWithLog, setIsUsingTool, setCurrentTool, setChatMessages]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = handleWebSocketMessage;
    }
  }, [ws, handleWebSocketMessage]);

  useEffect(() => {
    if (!isLoading) {
      setIsAppLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && user) {
        createOrUpdateUser(user);
        const savedMessages = localStorage.getItem('unauthenticatedChatMessages');
        const savedImageUrl = localStorage.getItem('unauthenticatedGeneratedImageUrl');
        if (savedMessages) {
          setChatMessages(JSON.parse(savedMessages));
          localStorage.removeItem('unauthenticatedChatMessages');
        }
        if (savedImageUrl) {
          setGeneratedImageUrl(savedImageUrl);
          localStorage.removeItem('unauthenticatedGeneratedImageUrl');
        }
      } else {
        if (location.pathname === '/chat' && !allowUnauthenticatedChat) {
          navigate('/', { replace: true });
        }
      }
    }
  }, [isLoading, isAuthenticated, user, navigate, location.pathname, allowUnauthenticatedChat]);

  const createOrUpdateUser = async (user: any) => {
    try {
      const storedUserDataKey = localStorage.getItem('userDataKey');
      const response = await axios.post(`${API_URL}/api/users`, {
        sub: user.sub,
        name: user.name,
        email: user.email,
        picture: user.picture,
        userDataKey: storedUserDataKey
      }, {
        withCredentials: true
      });

      setLocalUser({...response.data.user, auth0Id: user.sub});
      localStorage.removeItem('userDataKey');

      // If this is a new user with a chat, navigate to it
      if (response.data.isNewUser && response.data.chatId) {
        navigate(`/chat/${response.data.chatId}`, {
          state: { 
            initialChatId: response.data.chatId,
            skipFetch: response.data.skipFetch
          }
        });
      }
    } catch (error) {
      console.error('Error creating/updating user:', error);
    }
  };

  const updateUserNick = async (nick: string) => {
    if (!localUser) return;
    try {
      const response = await axios.put(`${API_URL}/api/users/${localUser.id}`, { nick });
      setLocalUser((prevUser: any) => ({ ...prevUser, nick }));
    } catch (error) {
      console.error('Error updating user nick:', error);
      throw error;
    }
  };

  const updateUserName = async (name: string) => {
    if (!user) return;
    try {
      const response = await axios.put(`${API_URL}/api/users/${user.sub}`, { name });
      setLocalUser((prevUser: any) => ({ ...prevUser, name }));
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (description.trim()) {
      setAllowUnauthenticatedChat(true);
      
      navigate('/chat');

      try {
        const response = await fetch(`${API_URL}/api/chat`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            message: description, 
            aspectRatio: '1:1'
          })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        setChatMessages([`User: ${description}`, `AI: ${data.content}`]);
        if (data.imageUrl) {
          setGeneratedImageUrl(data.imageUrl);
        }
        if (data.imageKey) {
          setImageKey(data.imageKey);
          localStorage.setItem('imageKey', data.imageKey);
        }
        if (data.userDataKey) {
          setUserDataKey(data.userDataKey);
          localStorage.setItem('userDataKey', data.userDataKey);
        }
      } catch (error) {
        console.error('Error submitting chat:', error);
        setChatMessages([`Error: An error occurred while processing your request.`]);
      } finally {
        setIsGeneratingImageWithLog(false);
      }
    }
  };

  const handleLogin = () => {
    if (!isAuthenticated && chatMessages.length > 0) {
      localStorage.setItem('unauthenticatedChatMessages', JSON.stringify(chatMessages));
      if (generatedImageUrl) {
        localStorage.setItem('unauthenticatedGeneratedImageUrl', generatedImageUrl);
      }
    }
    localStorage.setItem('loginRedirectPath', location.pathname);
    loginWithRedirect();
  };

  const sendMessage = async (message: string, aspectRatio: string, chatId?: string | null): Promise<ChatResponse> => {
    try {
      console.log('sendMessage called with:', { message, aspectRatio, chatId });
      const token = await getAccessTokenSilently();
      console.log('Got access token, sending request with chatId:', chatId);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat`,
        { message, aspectRatio, chatId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('Received response from server:', response.data);
      return response.data;
    } catch (error: any) {
      if (error.response?.status === 403 && error.response?.data?.upgradePlan) {
        console.log('Usage limit reached:', error.response.data);
        throw error;
      }

      console.error('Error sending message:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }

      if (!(error.response?.status === 403 && error.response?.data?.upgradePlan)) {
        toast({
          title: "Error",
          description: "Failed to send message. Please try again.",
          variant: "destructive",
        });
      }

      throw error;
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setAuthChecked(true);
    }
  }, [isLoading]);

  useEffect(() => {
    const setAuthToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } catch (error) {
        }
      }
    };

    setAuthToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  useEffect(() => {
    // Check if user just logged in and has pending campaign data
    if (isAuthenticated && localStorage.getItem('pendingCampaignData')) {
      const campaignData = JSON.parse(localStorage.getItem('pendingCampaignData') || '{}');
      // Clear the pending data
      localStorage.removeItem('pendingCampaignData');
      // Navigate to chat page with the campaign data
      navigate('/chat', { state: { campaignData } });
    }
  }, [isAuthenticated]);

  const homePageProps = {
    description: description,
    setDescription: setDescription,
    onSubmit: handleSubmit,
    isAuthenticated: isAuthenticated,
    user: localUser,
    loginWithRedirect: loginWithRedirect,
    logout: logout,
    onSettingsClick: () => navigate('/settings'),
    onPricingClick: () => navigate('/pricing')
  };

  const chatPageProps = {
    initialDescription: description,
    chatMessages: chatMessages,
    setChatMessages: setChatMessages,
    generatedImageUrl: generatedImageUrl,
    setGeneratedImageUrl: setGeneratedImageUrl,
    isGeneratingImage: isGeneratingImage,
    setIsGeneratingImage: setIsGeneratingImageWithLog,
    sendMessage: sendMessage,
    isAuthenticated: isAuthenticated,
    user: localUser,
    loginWithRedirect: handleLogin,
    logout: logout,
    onSettingsClick: () => navigate('/settings'),
    onPricingClick: () => navigate('/pricing'),
    latestCampaign: latestCampaign,
    currentBrand: currentBrand,
    forceUpdate: forceUpdate,
    imageKey: imageKey,
    setImageKey: setImageKey,
    aspectRatio: '1:1',
    followUpPrompts: followUpPrompts,
    setFollowUpPrompts: setFollowUpPrompts,
    isUsingTool: isUsingTool,
    currentTool: currentTool,
    initialChatId: currentChatId,
    setCurrentChatId: setCurrentChatId,
  };

  const settingsPageProps = {
    onClose: () => navigate(-1),
    onSettingsClick: () => navigate('/settings'),
    isAuthenticated: isAuthenticated,
    localUser: localUser,
    loginWithRedirect: loginWithRedirect,
    logout: logout,
    onPricingClick: () => {
      console.log('Navigating to pricing');
      navigate('/pricing');
    },
    updateUserName: updateUserName,
    updateUserNick: updateUserNick
  };

  const handleLogout = () => {
    localStorage.removeItem('currentChatId');
    setCurrentChatId(null);
    logout();
  };

  const pricingPageProps = {
    onClose: () => navigate(-1)
  };

  return (
    <TooltipProvider delayDuration={100}>
      <div className="App">
        <AppRoutes 
          isAuthenticated={isAuthenticated}
          homePageProps={homePageProps}
          chatPageProps={chatPageProps}
          settingsPageProps={settingsPageProps}
          pricingPageProps={pricingPageProps}
        />
      </div>
    </TooltipProvider>
  );
};

export default App;
