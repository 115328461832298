// src/hooks/useCampaign.ts
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Campaign {
  id: string;
  name: string;
  target_audience: string;
  insight: string;
  creative_concept: string;
  product_id: string | null;
  location: string;
}

export function useCampaign(brandId: string | undefined) {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [editedCampaign, setEditedCampaign] = useState<Campaign | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCampaigns = useCallback(async () => {
    if (!brandId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/brands/${brandId}/campaigns`);
      setCampaigns(response.data);
      if (response.data.length > 0) {
        const latestCampaign = response.data[response.data.length - 1];
        setSelectedCampaign(latestCampaign);
        setEditedCampaign(latestCampaign);
      }
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      setError('Failed to fetch campaigns');
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  const createCampaign = useCallback(async (newCampaign: Omit<Campaign, 'id'>): Promise<Campaign> => {
    if (!brandId) throw new Error('No brand selected');
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`/api/brands/${brandId}/campaigns`, newCampaign);
      setCampaigns(prevCampaigns => [...prevCampaigns, response.data]);
      return response.data;
    } catch (err) {
      console.error('Error creating campaign:', err);
      setError('Failed to create campaign');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  const updateCampaign = useCallback(async (campaignId: string, updatedFields: Partial<Campaign>): Promise<Campaign> => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`/api/campaigns/${campaignId}`, updatedFields);
      const updatedCampaign = response.data;
      setCampaigns(prevCampaigns => 
        prevCampaigns.map(c => c.id === updatedCampaign.id ? updatedCampaign : c)
      );
      if (selectedCampaign?.id === updatedCampaign.id) {
        setSelectedCampaign(updatedCampaign);
        setEditedCampaign(updatedCampaign);
      }
      setIsDirty(false);
      return updatedCampaign;
    } catch (error) {
      console.error('Error updating campaign:', error);
      setError('Failed to update campaign');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const saveCampaignChanges = async () => {
    if (!isDirty || !editedCampaign || !selectedCampaign) return null;
    try {
      const response = await axios.put(`/api/campaigns/${selectedCampaign.id}`, editedCampaign);
      setSelectedCampaign(response.data);
      setCampaigns(prevCampaigns => 
        prevCampaigns.map(c => c.id === response.data.id ? response.data : c)
      );
      setEditedCampaign(response.data);
      setIsDirty(false);
      return response.data;
    } catch (error) {
      console.error('Error saving campaign:', error);
      throw error;
    }
  };

  const deleteCampaign = useCallback(async (campaignId: string): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      await axios.delete(`/api/campaigns/${campaignId}`);
      setCampaigns(prevCampaigns => prevCampaigns.filter(c => c.id !== campaignId));
      if (selectedCampaign?.id === campaignId) {
        setSelectedCampaign(null);
        setEditedCampaign(null);
      }
    } catch (err) {
      console.error('Error deleting campaign:', err);
      setError('Failed to delete campaign');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [selectedCampaign]);

  const selectCampaign = useCallback((campaignId: string | null) => {
    const campaign = campaignId ? campaigns.find(c => c.id === campaignId) || null : null;
    setSelectedCampaign(campaign);
    setEditedCampaign(campaign);
    setIsDirty(false);
  }, [campaigns]);

  const setEditedCampaignState = (campaign: Campaign | null) => setEditedCampaign(campaign);

  useEffect(() => {
    if (brandId) {
      fetchCampaigns();
    } else {
      setCampaigns([]);
      setSelectedCampaign(null);
      setEditedCampaign(null);
    }
  }, [brandId, fetchCampaigns]);

  return { 
    campaigns, 
    selectedCampaign,
    editedCampaign,
    setEditedCampaign: setEditedCampaignState, 
    loading, 
    error, 
    fetchCampaigns, 
    createCampaign, 
    updateCampaign, 
    deleteCampaign, 
    saveCampaignChanges,
    selectCampaign,
    isDirty,
    setIsDirty
  };
}