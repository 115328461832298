export const PLAN_LIMITS = {
  free: {
    tool_chat_daily: 5,
    brand_training_monthly: 0,
    product_training_monthly: 0,
    max_brands: 1,
    features: ['basic_chat', 'basic_image_generation']
  },
  basic: {
    tool_chat_daily: 30,
    brand_training_monthly: 5,
    product_training_monthly: 5,
    max_brands: 1,
    features: ['basic_chat', 'basic_image_generation', 'brand_training', 'product_training']
  },
  pro: {
    tool_chat_daily: 100,
    brand_training_monthly: 20,
    product_training_monthly: 20,
    max_brands: 5,
    features: ['basic_chat', 'basic_image_generation', 'brand_training', 'product_training', 'advanced_analytics']
  }
} as const; 