import React from 'react';
import { Card } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Save } from 'lucide-react';

interface ProfileCardProps {
  nick: string;
  onNickChange: (value: string) => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ 
  nick, 
  onNickChange, 
  setHasUnsavedChanges 
}) => {
  return (
    <Card className="p-6 bg-black border border-[#333]">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-white">Profile</h3>
        </div>
        <div className="text-sm text-gray-400">
          How should we call you?
        </div>
        <div className="flex gap-4">
          <Input
            id="userNick"
            value={nick}
            onChange={(e) => {
              onNickChange(e.target.value);
              setHasUnsavedChanges(true);
            }}
            className="flex-1 bg-gray-800 border-gray-700 text-white focus:border-gray-600"
          />
       
        </div>
      </div>
    </Card>
  );
};

export default ProfileCard; 