import React, { useEffect, useState } from 'react';
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { DiamondOutlined } from '@mui/icons-material';
import { PLAN_LIMITS } from '../utils/planLimits';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

interface UsageStats {
  daily_tool_chat_count: number;
  monthly_brand_training_count: number;
  monthly_product_training_count: number;
  last_tool_chat_reset: string;
  last_monthly_reset: string;
}

interface UsageDashboardProps {
  userPlan: 'free' | 'basic' | 'pro';
  brands: any[];
  onPricingClick: () => void;
}

const UsageDashboard: React.FC<UsageDashboardProps> = ({ 
  userPlan, 
  brands, 
  onPricingClick,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [usageStats, setUsageStats] = useState<UsageStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsageStats = async () => {
      try {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/usage`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setUsageStats(response.data);
      } catch (error) {
        console.error('Error fetching usage stats:', error);
        setError('Failed to load usage statistics');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsageStats();
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return <Card className="p-6 bg-black border border-[#333]">
      <div className="text-white">Loading usage data...</div>
    </Card>;
  }

  if (error) {
    return <Card className="p-6 bg-black border border-[#333]">
      <div className="text-red-500">{error}</div>
    </Card>;
  }

  return (
    <Card className="p-6 bg-black border border-[#333]">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-white">Usage Dashboard</h3>
          <span className="text-xs text-gray-400">Current billing period</span>
        </div>
        
        {/* AI Generations */}
        <div className="space-y-3">
          <div className="flex justify-between items-end">
            <div>
              <span className="text-sm text-gray-400">AI Generations</span>
              <p className="text-2xl font-semibold text-white">
                {PLAN_LIMITS[userPlan].tool_chat_daily}
                <span className="text-sm text-gray-400 ml-1">per day</span>
              </p>
            </div>
            <span className="text-sm font-medium text-white">
              {usageStats?.daily_tool_chat_count || 0}/{PLAN_LIMITS[userPlan].tool_chat_daily}
            </span>
          </div>
          <div className="w-full bg-gray-800 rounded-full h-1.5">
            <div 
              className="h-1.5 rounded-full transition-all duration-500 bg-white"
              style={{ 
                width: `${((usageStats?.daily_tool_chat_count || 0) / PLAN_LIMITS[userPlan].tool_chat_daily) * 100}%` 
              }}
            />
          </div>
        </div>

        {/* Brand Training */}
        <div className="space-y-3">
          <div className="flex justify-between items-end">
            <div>
              <span className="text-sm text-gray-400">Brand Training</span>
              <p className="text-2xl font-semibold text-white">
                {PLAN_LIMITS[userPlan].brand_training_monthly}
                <span className="text-sm text-gray-400 ml-1">per month</span>
              </p>
            </div>
            <span className="text-sm font-medium text-white">
              {usageStats?.monthly_brand_training_count || 0}/{PLAN_LIMITS[userPlan].brand_training_monthly}
            </span>
          </div>
          <div className="w-full bg-gray-800 rounded-full h-1.5">
            <div 
              className="h-1.5 rounded-full transition-all duration-500 bg-white"
              style={{ 
                width: `${((usageStats?.monthly_brand_training_count || 0) / PLAN_LIMITS[userPlan].brand_training_monthly) * 100}%` 
              }}
            />
          </div>
        </div>

        {/* Brands */}
        <div className="space-y-3">
          <div className="flex justify-between items-end">
            <div>
              <span className="text-sm text-gray-400">Active Brands</span>
              <p className="text-2xl font-semibold text-white">
                {PLAN_LIMITS[userPlan].max_brands}
                <span className="text-sm text-gray-400 ml-1">maximum</span>
              </p>
            </div>
            <span className="text-sm font-medium text-white">
              {brands.length}/{PLAN_LIMITS[userPlan].max_brands}
            </span>
          </div>
          <div className="w-full bg-gray-800 rounded-full h-1.5">
            <div 
              className="h-1.5 rounded-full transition-all duration-500 bg-white"
              style={{ 
                width: `${(brands.length / PLAN_LIMITS[userPlan].max_brands) * 100}%` 
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UsageDashboard;