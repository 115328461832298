import { useState, useCallback, useRef } from 'react';

const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_INTERVAL = 5000; // 5 seconds

export const useWebSocketConnection = (url: string) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const reconnectAttemptsRef = useRef(0);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isConnectingRef = useRef(false);

  const connect = useCallback(() => {
    if (ws || isConnectingRef.current) {
      console.log('WebSocket connection already exists or is in progress');
      return ws;
    }

    isConnectingRef.current = true;
    console.log('Attempting to connect to WebSocket:', url);
    const socket = new WebSocket(url);

    socket.onopen = () => {
      console.log('WebSocket connection established successfully');
      setWs(socket);
      reconnectAttemptsRef.current = 0;
      isConnectingRef.current = false;
    };

    socket.onerror = (error) => {
      console.error('WebSocket connection error:', error);
      isConnectingRef.current = false;
    };

    socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event.code, event.reason);
      setWs(null);
      isConnectingRef.current = false;

      if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttemptsRef.current += 1;
        console.log(`Attempting to reconnect (${reconnectAttemptsRef.current}/${MAX_RECONNECT_ATTEMPTS})...`);
        reconnectTimeoutRef.current = setTimeout(() => {
          connect();
        }, RECONNECT_INTERVAL);
      } else {
        console.log('Max reconnection attempts reached. Please refresh the page.');
      }
    };

    return socket;
  }, [url, ws]);

  const disconnect = useCallback(() => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    if (ws) {
      ws.close();
      setWs(null);
    }
    isConnectingRef.current = false;
    reconnectAttemptsRef.current = 0;
  }, [ws]);

  return { ws, connect, disconnect };
};