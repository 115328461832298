// src/components/SettingsPage.tsx
import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Business, Person, Lock, History, ChevronLeft, DiamondOutlined } from '@mui/icons-material';
import '../styles/SettingsPage.css';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import PricingPage from './PricingPage';
import { useAuth0 } from '@auth0/auth0-react';
import CustomAlertDialog from './ui/CustomAlertDialog';
import { useBrand, Brand } from '../hooks/useBrand';
import { ProductForm } from './productForm';
import { CampaignForm } from './campaignForm';
import { useProduct, Product } from '../hooks/useProduct';
import { useCampaign, Campaign } from '../hooks/useCampaign';
import BrandForm from './brandForm';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./ui/tabs";
import axios from 'axios';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { PLAN_LIMITS } from '../utils/planLimits';
import { Card } from "./ui/card";
import UsageDashboard from './UsageDashboard';
import ProfileCard from './ProfileCard';
import PlanCard from './PlanCard';

interface SettingsPageProps {
  isAuthenticated: boolean;
  localUser: any;
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onClose: () => void;
  onPricingClick: () => void;
  updateUserName: (name: string) => Promise<void>;
  updateUserNick: (nick: string) => Promise<void>;
}

interface Chat {
  id: number;
  chatName: string;
  created_at: string;
}

const menuItems = [
  { id: 'brands', icon: <Business />, label: 'Brands' },
  { id: 'account', icon: <Person />, label: 'Account' },
  // { id: 'security', icon: <Lock />, label: 'Security' },
  { id: 'history', icon: <History />, label: 'History' },
];

const SettingsPage: React.FC<SettingsPageProps> = ({
  isAuthenticated,
  localUser,
  loginWithRedirect,
  logout,
  onSettingsClick,
  onClose,
  onPricingClick,
  updateUserName,
  updateUserNick
}) => {
  const { brands, selectedBrand, editedBrand, loading: brandsLoading, error: brandsError, fetchBrands, updateBrand, setSelectedBrand, createBrand, saveChanges: saveBrandChanges, isDirty: brandIsDirty } = useBrand(localUser);
  const { 
    products, 
    selectedProduct, 
    editedProduct, 
    setEditedProduct,
    loading: productsLoading, 
    error: productsError, 
    fetchProducts, 
    createProduct, 
    updateProduct, 
    deleteProduct, 
    saveProductChanges,
    selectProduct,
    isDirty: productIsDirty,
    setIsDirty: setProductIsDirty
  } = useProduct(selectedBrand?.id);
  const { 
    campaigns, 
    selectedCampaign, 
    editedCampaign, 
    setEditedCampaign,
    loading: campaignsLoading, 
    error: campaignsError, 
    fetchCampaigns, 
    createCampaign, 
    updateCampaign, 
    deleteCampaign, 
    saveCampaignChanges, 
    selectCampaign,
    isDirty: campaignIsDirty,
    setIsDirty: setCampaignIsDirty // Add this line
  } = useCampaign(selectedBrand?.id);
  
  const [selectedMenu, setSelectedMenu] = useState<string>('brands');
  const [selectedSection, setSelectedSection] = useState<string>('details');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [newNick, setNewNick] = useState<string>(localUser?.nick || localUser?.name || '');
  const { user, getAccessTokenSilently } = useAuth0();
  
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
  const [pendingAction, setPendingAction] = useState<() => void>(() => {});
  const navigate = useNavigate();
  const [chats, setChats] = useState<Chat[]>([]);

  const [userPlan, setUserPlan] = useState<'free' | 'basic' | 'pro'>('free');

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/me`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setUserPlan(response.data.plan_type || 'free');
      } catch (error) {
        console.error('Error fetching user plan:', error);
      }
    };

    if (isAuthenticated) {
      fetchUserPlan();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (localUser && localUser.id) {
      fetchBrands();
    }
  }, [localUser, fetchBrands]);

  useEffect(() => {
    if (selectedBrand) {
      fetchProducts();
      fetchCampaigns();
    }
  }, [selectedBrand, fetchProducts, fetchCampaigns]);

  useEffect(() => {
    setHasUnsavedChanges(brandIsDirty || productIsDirty || campaignIsDirty);
  }, [brandIsDirty, productIsDirty, campaignIsDirty]);

  useEffect(() => {
    fetchChats();
  }, []);

  const handleSaveAllChanges = async () => {
    try {
      let updatedData = [];

      if (brandIsDirty && editedBrand) {
        const brandData = await saveBrandChanges();
        if (brandData) updatedData.push({ type: 'Brand', data: brandData });
      }

      if (productIsDirty && editedProduct) {
        const productData = await saveProductChanges();
        if (productData) updatedData.push({ type: 'Product', data: productData });
      }

      if (campaignIsDirty && editedCampaign) {
        const campaignData = await saveCampaignChanges();
        if (campaignData) updatedData.push({ type: 'Campaign', data: campaignData });
      }

      if (newNick !== localUser?.nick) {
        await updateUserNick(newNick);
        updatedData.push({ type: 'User', data: { nick: newNick } });
      }

      if (updatedData.length > 0) {
        console.log('Changes saved successfully:', updatedData);
        setShowSuccessAlert(true);
        setHasUnsavedChanges(false);
      } else {
        console.log('No changes to save');
        setErrorMessage('No changes to save');
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setErrorMessage('An error occurred while saving changes.');
      setShowErrorAlert(true);
    }
  };

  const handleCreateNewBrand = async () => {
    try {
      if (!localUser) return;

      // Get current brand count
      const currentBrandCount = brands.length;
      const planLimits = PLAN_LIMITS[userPlan];

      if (currentBrandCount >= planLimits.max_brands) {
        setErrorMessage(
          `Your ${userPlan} plan allows up to ${planLimits.max_brands} brands. ` +
          'Upgrade your plan to create more brands.'
        );
        setShowErrorAlert(true);
        return;
      }

      const newBrand = await createBrand();
      setSelectedBrand(newBrand);
    } catch (error: any) {
      if (error.response?.data?.upgradePlan) {
        setErrorMessage(error.response.data.message);
        setShowErrorAlert(true);
      } else {
        console.error('Error creating brand:', error);
        setErrorMessage('Failed to create new brand');
        setShowErrorAlert(true);
      }
    }
  };

  const handleCreateNewProduct = async () => {
    if (!selectedBrand) return;
    try {
      await createProduct({ name: 'New Product' });
      fetchProducts();
    } catch (error) {
      console.error('Error creating new product:', error);
      setErrorMessage('Failed to create a new product');
      setShowErrorAlert(true);
    }
  };

  const handleCreateNewCampaign = async () => {
    if (!selectedBrand) return;
    try {
      await createCampaign({ 
        name: 'New Campaign',
        target_audience: '',
        insight: '',
        creative_concept: '',
        product_id: null,
        location: '' // Added missing required location field
      });
      fetchCampaigns();
    } catch (error) {
      console.error('Error creating new campaign:', error);
      setErrorMessage('Failed to create a new campaign');
      setShowErrorAlert(true);
    }
  };

  const handleMenuChange = (menuId: string) => {
    if (hasUnsavedChanges) {
      setPendingAction(() => () => setSelectedMenu(menuId));
      setShowUnsavedChangesAlert(true);
    } else {
      setSelectedMenu(menuId);
    }
  };

  const fetchChats = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chats`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setChats(response.data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const handleChatSelect = (chatId: number) => {
    navigate(`/chat/${chatId}`);
  };

  const renderBrandsContent = () => {
    if (brandsLoading) return <div>Loading brands...</div>;
    if (brandsError) return <div className="error-message">{brandsError}</div>;

    return (
      <div className="content-section">
        <div className="flex justify-between items-center mb-4">
          <div className="flex-grow mr-4">
            <Select
              value={selectedBrand?.id || ''}
              onValueChange={(value) => setSelectedBrand(brands.find(b => b.id === value) || null)}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a brand" />
              </SelectTrigger>
              <SelectContent>
                {brands.map((brand) => (
                  <SelectItem key={brand.id} value={brand.id}>{brand.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button 
                    onClick={handleCreateNewBrand} 
                    variant="secondary"
                    disabled={brands.length >= PLAN_LIMITS[userPlan].max_brands}
                  >
                    Create New Brand ({brands.length}/{PLAN_LIMITS[userPlan].max_brands})
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {brands.length >= PLAN_LIMITS[userPlan].max_brands
                  ? `Upgrade your plan to create more than ${PLAN_LIMITS[userPlan].max_brands} brands`
                  : 'Create a new brand'
                }
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {selectedBrand && editedBrand && (
          <Tabs defaultValue="details">
            <TabsList>
              <TabsTrigger value="details">Brand Details</TabsTrigger>
              <TabsTrigger value="products">Products</TabsTrigger>
              <TabsTrigger value="campaigns">Campaigns</TabsTrigger>
            </TabsList>
            <TabsContent value="details">
              <BrandForm
                brand={editedBrand || selectedBrand}
                onUpdate={updateBrand}
                userPlan={userPlan}
              />
            </TabsContent>
            <TabsContent value="products">
              {renderProductsContent()}
            </TabsContent>
            <TabsContent value="campaigns">
              {renderCampaignsContent()}
            </TabsContent>
          </Tabs>
        )}
      </div>
    );
  };

  const renderProductsContent = () => {
    return (
      <div>
        <h3 className="text-lg font-semibold mb-2">Products</h3>
        {productsLoading ? (
          <div>Loading products...</div>
        ) : productsError ? (
          <div className="error-message">{productsError}</div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-4">
              <div className="flex-grow mr-4">
                <Select
                  value={selectedProduct?.id || ''}
                  onValueChange={(value) => selectProduct(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a product" />
                  </SelectTrigger>
                  <SelectContent>
                    {products.map((product) => (
                      <SelectItem key={product.id} value={product.id}>{product.name}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button onClick={handleCreateNewProduct} variant="secondary">Create New Product</Button>
              {selectedProduct && (
                <Button 
                  onClick={async () => {
                    if (selectedProduct) {
                      await deleteProduct(selectedProduct.id);
                      selectProduct(null);
                    }
                  }} 
                  variant="destructive"
                  className="ml-2"
                >
                  Delete Product
                </Button>
              )}
            </div>
            {selectedProduct && (
              <ProductForm
                product={editedProduct || selectedProduct}
                onUpdate={(updatedFields: Partial<Product>) => {
                  setEditedProduct((prev) => {
                    if (prev) {
                      return { ...prev, ...updatedFields };
                    }
                    return prev;
                  });
                  setProductIsDirty(true);
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const renderCampaignsContent = () => {
    return (
      <div>
        <h3 className="text-lg font-semibold mb-2">Campaigns</h3>
        {campaignsLoading ? (
          <div>Loading campaigns...</div>
        ) : campaignsError ? (
          <div className="error-message">{campaignsError}</div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-4">
              <div className="flex-grow mr-4">
                <Select
                  value={selectedCampaign?.id || ''}
                  onValueChange={(value) => selectCampaign(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a campaign" />
                  </SelectTrigger>
                  <SelectContent>
                    {campaigns.map((campaign) => (
                      <SelectItem key={campaign.id} value={campaign.id}>{campaign.name}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button onClick={handleCreateNewCampaign} variant="secondary">Create New Campaign</Button>
              {selectedCampaign && (
                <Button 
                  onClick={() => deleteCampaign(selectedCampaign.id)} 
                  variant="destructive"
                  className="ml-2"
                >
                  Delete Campaign
                </Button>
              )}
            </div>
            {selectedCampaign && (
              <CampaignForm
                campaign={editedCampaign || selectedCampaign}
                products={products}
                onUpdate={(updatedFields: Partial<Campaign>) => {
                  if (selectedCampaign) {
                    setEditedCampaign({ ...selectedCampaign, ...updatedFields } as Campaign);
                    setCampaignIsDirty(true);
                  }
                }}
                onDelete={async () => {
                  if (selectedCampaign) {
                    await deleteCampaign(selectedCampaign.id);
                    selectCampaign(null);
                  }
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };

    const renderAccountContent = () => {
      return (
        <div className="content-section space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <ProfileCard
              nick={newNick}
              onNickChange={setNewNick}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
            <PlanCard
              userPlan={userPlan}
              onPricingClick={onPricingClick}
            />
          </div>

          {/* Usage Dashboard */}
          <UsageDashboard 
            userPlan={userPlan}
            brands={brands}
            onPricingClick={onPricingClick}
          />
        </div>
      );
    };

    const renderHistoryContent = () => {
      return (
        <div className="content-section h-full">
          <div className="h-[calc(100vh-200px)] overflow-y-auto">
            <div className="space-y-2">
              {chats.map((chat) => (
                <div 
                  key={chat.id} 
                  onClick={() => handleChatSelect(chat.id)} 
                  className="p-4 rounded-lg border border-[#333] hover:border-gray-700 cursor-pointer transition-colors"
                >
                  <div className="text-white font-medium">
                    {chat.chatName || `Chat ${chat.id}`}
                  </div>
                  <div className="text-sm text-gray-400">
                    {new Date(chat.created_at).toLocaleDateString()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };

    const renderContent = () => {
      switch (selectedMenu) {
        case 'brands':
          return renderBrandsContent();
        case 'account':
          return renderAccountContent();
        case 'history':
          return renderHistoryContent();
        // Implement other cases like 'security', 'history' as needed
        default:
          return null;
      }
    };

    if (showPricing) {
      return <PricingPage onClose={() => setShowPricing(false)} />;
    }

    return (
      <div className="settings-page min-h-screen bg-black-100">
        <Header 
          page="settings"
          onBackClick={() => navigate('/chat')}
          isAuthenticated={isAuthenticated}
          user={localUser}
          loginWithRedirect={loginWithRedirect}
          logout={logout}
          onSettingsClick={onSettingsClick}
          onPricingClick={onPricingClick}
        />
        <div className="settings-container flex">
          <div className="settings-sidebar w-64 bg-black p-4 shadow-md">
            <nav className="space-y-2">
              {menuItems.map((item) => (
                <Button
                  key={item.id}
                  variant={selectedMenu === item.id ? 'default' : 'ghost'}
                  className={`w-full justify-start ${selectedMenu === item.id ? 'bg-gray-200' : ''}`}
                  onClick={() => setSelectedMenu(item.id)}
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </Button>
              ))}
            </nav>
          </div>
          <div className="settings-main flex-1 p-6">
            <div className="settings-header flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">Settings</h2>
              <Button onClick={handleSaveAllChanges} disabled={!hasUnsavedChanges}>
                Save All Changes
              </Button>
            </div>
            {renderContent()}
          </div>
        </div>
        <CustomAlertDialog
          isOpen={showSuccessAlert}
          onOpenChange={setShowSuccessAlert}
          title="Success"
          description="All changes have been saved successfully."
          confirmText="OK"
          onConfirm={() => setShowSuccessAlert(false)}
        />
        <CustomAlertDialog
          isOpen={showErrorAlert}
          onOpenChange={setShowErrorAlert}
          title="Error"
          description={errorMessage}
          confirmText="OK"
          onConfirm={() => setShowErrorAlert(false)}
        />
        <CustomAlertDialog
          isOpen={showUnsavedChangesAlert}
          onOpenChange={setShowUnsavedChangesAlert}
          title="Unsaved Changes"
          description="You have unsaved changes. Do you want to save them before leaving?"
          confirmText="Save"
          cancelText="Discard"
          onConfirm={() => {
            handleSaveAllChanges();
            pendingAction();
            setShowUnsavedChangesAlert(false);
          }}
          onCancel={() => {
            pendingAction();
            setShowUnsavedChangesAlert(false);
            setHasUnsavedChanges(false);
          }}
        />
      </div>
    );
  };

export default SettingsPage;